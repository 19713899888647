import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { types } from '../../redux/actions/types';
import ButtonWithSpinner from "../../Components/ButtonWithSpinner";
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import { CONSTANTVALUE, getData, isEmpty, isLoggedInUser, removeDuplicates } from '../../utils'
import { getUserDetails, editUser, addUser, getRoles, getModules, getRoleDetails } from '../../redux/actions/userAdminAction'
import { getCompany } from '../../redux/actions/companyAction'
import { Select, Typography, Spin, Card } from 'antd';
import { Box, Button, Divider, SimpleGrid, Text, useToast, Grid, TagLabel, TagRightIcon, Tag, Flex, useBreakpointValue, Tooltip, Radio, HStack, VStack, Switch, Input, } from '@chakra-ui/react';
import { projectList } from '../../redux/actions/projectAdminAction';
import { adminProjectListAPI, getProjectListByCompanyId } from '../../store/apis';
import { apiCallWithPromise, apiCallWithPromiseToken, apiCallWithPromiseTokenFormPosting, apiGetCallWithPromise } from '../../store/axiosApicall';
import { apiName } from '../../store/serverConfig';
import { MdAdd, MdClose, MdConstruction, MdOutlineConstruction, MdWorkspaces } from 'react-icons/md';
import { isValidPhone } from '../../utils/validator';
// import {getModules,addRoles,getRoleDetails,updateRole} from '../../redux/actions/userAdminAction'
// import {AddUser,updateProject,getProjectById} from '../../redux/actions/projectAdminAction'
// import {getSiteDetails} from '../../redux/actions/siteAction'
// import mainLogo from "../../assets/images/aqualogix-logo-b.png";
// import harGharJalLogo from "../../assets/images/hargharjal-logo-b.png";
let selModules = [];
const AddUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [operatorName, setOperatorName] = useState('');
  const [designation, setDesignation] = useState('');
  const [dontShow, setDontShow] = useState(false);
  const [listProjects, setListProjects] = useState([]);
  const [listCompany, setListCompany] = useState([]);
  const [listModule, setListModule] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [empType, setEmpType] = useState('');
  const [company, setCompany] = useState('');
  const [isDesire, setIsDesire] = useState(false);
  const [adminProject, setAdminList] = useState([]);
  const [mobileno, setMobileno] = useState('');
  const [password, setPassword] = useState('');
  const [selectedSites, setSelectedSites] = useState([])
  const [isActive, setIsACtive] = useState(null);
  const [updatedUserAssignedSites, setUpdatedUserAssignedSites] = useState([]);
  const [localUserId, setLocalUserId] = useState(null);
  const [listRole, setListRole] = useState([]);
  const [render, setRender] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [myMap, setMyMap] = useState(new Map());
  const [isPMSC, setPMSC] = useState(null);
  const [canEditReason, setEditReason] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    birthdate: "",
    employeeId: "",
    gender: "Male",
    email: "",
    workProfile: "",
    collar: "Blue Collar",
    onRoll: "Desire",
    latitude: "",
    longitude: "",
    applicableForVoucher: false,
    warehouses: [],
  });

  const { Text } = Typography;
  const { Option } = Select;


  const validateForm = () => {
    const requiredFields = ["birthdate", "employeeId", "gender", "email", "workProfile", "collar", "onRoll"];

    for (const field of requiredFields) {
      if (!employeeData[field]) {
        return false; // Validation failed
      }
    }

    for (let i = 0; i < employeeData.warehouses.length; i++) {
      let curr = employeeData.warehouses[i];
      if (curr.name.length == 0 || curr.latitude.length === 0 || curr.longitude.length == 0) return false;
    }

    return true; // Validation passed
  };

  useEffect(() => {
    console.log("PIKACHUX", employeeData)
  }, [employeeData])
  // const [inImei, setInImei] = useState(''); 
  // const [isLoading, setIsLoading] = useState(false);
  const userAdminReducer = useSelector(state => state.userAdminReducer);
  const companyReducer = useSelector(state => state.companyReducer);
  // const userAdminReducer = useSelector(state => state.userAdminReducer);
  const location = useLocation();
  const toast = useToast()

  useEffect(() => {
    fetchProjectList();
    fetchCompanyApi()
  }, [])


  const fetchCompanyApi = async () => {
    const data = await getData(CONSTANTVALUE.USEROBJECT);
    console.log("XXX DATA", data.companyDetails._id)
    if (data.companyDetails._id == '6434f97aedec8f1fa5852145') {
      setIsDesire(true)
    } else {

      const d = await getProjectListByCompanyId(`companyId=${data.companyDetails._id}`);
      let projects = [];
      for (let i = 0; i < d?.data?.length; i++) projects.push(d.data[i]._id);
      setAdminList(projects)
    }
  }

  useEffect(() => {
    if (localUserId != null && updatedUserAssignedSites.length == 0)

      fetchAssignedSiteId();
  }, [localUserId])

  const fetchAssignedSiteId = async () => {
    try {
      const response = await apiGetCallWithPromise(apiName.geAllAssignedSitesByUserId, "userId=" + localUserId);
      console.log("xxx huh response", response);
      if (response?.data?.length > 0) {
        setUpdatedUserAssignedSites(response.data);
        console.log('xxx huh curr', response.data);
        setSelectedSites(response.data);

        let arr = response.data;
        let updatedMap = new Map(myMap);

        for (let i = 0; i < arr.length; i++) {
          const projectId = arr[i].projectId;

          if (updatedMap.has(projectId)) {
            // If the key already exists, retrieve the existing array and add the new element to it
            const existingValue = updatedMap.get(projectId);
            existingValue.push(arr[i]);
            updatedMap.set(projectId, existingValue);
          } else {
            // If the key does not exist, create a new array with the element as the value
            updatedMap.set(projectId, [arr[i]]);
          }
        }

        setMyMap(updatedMap);
      }

    } catch (error) {
      console.log(error?.message)
    }
  }

  const fetchProjectList = async () => {
    try {
      const curr = await adminProjectListAPI("page=1&limit=10000");
      curr.data.list = curr.data.list.map((item) => {
        return {
          ...item,
          sites: []
        };
      });
      setListProjects(curr.data.list)
    } catch (error) {
      console.log('Error occured', error?.message)
    }

  }

  useEffect(() => {
    getAllSitesForEachProject();
  }, [listProjects]);


  const getAllSitesForEachProject = async () => {
    console.log('innnnnnnnn', listProjects)

    if (listProjects.length === 0) return;

    console.log('xxx result inn', listProjects)
    const apiCalls = listProjects.map(async (project) => {
      const response = await apiGetCallWithPromise(apiName.getSitesByProjectIdLtdAPI, "projectID=" + project._id);
      project.sites = response.data;
      console.log('xxx response ==> ', response.data);
      return response;
    });

    console.log('xxx final', listProjects)

    try {
      const allResponses = await Promise.all(apiCalls);
      // 'allResponses' will be an array containing the responses from all API calls.
      // You can further process the data here as needed.
    } catch (error) {
      // Handle any errors that might occur during API calls.
      console.error('Error occurred during API calls:', error);
    }
  };



  useEffect(() => {
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    // chkLogin(); 
    // setIsLoad(true);
    fetchCompanyList();
    fetchRoleList();
    fetchModuleList();
    if (location) {
      if (location.state) {

        if (location.state.dontShow) {
          setDontShow(true)
        } if (location.state.userId) {
          setLocalUserId(location.state.userId);
          fetchUserDetails();
        }
      }
    }
  }, [])


  // const bindPreviouslyAssignedSites = () => {
  //   console.log('xxx pika', updatedUserAssignedSites)
  //   return updatedUserAssignedSites.map((item, index) => (
  //     item.siteName ?
  //     <Tag
  //     size={'md'}
  //     key={'md'}
  //     variant='solid'
  //     colorScheme='blue'
  //     // style={{ marginBottom: useBreakpointValue({ base: '4px', md: '8px' }) }}
  //   >
  //     <TagLabel>{item.siteName}</TagLabel>
  //     <TagRightIcon as={MdOutlineConstruction} />
  //   </Tag> : null
  //   ));
  // };


  useEffect(() => {
    // console.log('AddUser-userReducer-useEffect',userAdminReducer);    
    if (userAdminReducer.action) {
      if (userAdminReducer.action === types.GET_ROLES_SUCCESS) {
        // console.log('User-userAdminReducer-useEffect',userAdminReducer)      
        if (userAdminReducer.status === "success") {
          setListRole(userAdminReducer.data);
        } else {
          toast({
            title: userAdminReducer?.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      else if (userAdminReducer.action === types.ADD_USER_REQUEST_SUCCESS) {
        // console.log('LoginScreen-userReducer-useEffect',userReducer)        
        if (userAdminReducer.status === "success") {
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          navigate('/Admin/Users');
        } else {
          toast({
            title: userAdminReducer?.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      } else if (userAdminReducer.action === types.EDIT_USER_REQUEST_SUCCESS) {
        // console.log('LoginScreen-userReducer-useEffect',userReducer)        
        if (userAdminReducer.status === "success") {
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          navigate('/Admin/Users');
        } else {
          toast({
            title: userAdminReducer?.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      } else if (userAdminReducer.action === types.GET_USER_DETAIL_REQUEST_SUCCESS) {
        if (userAdminReducer.status === "success") {
          console.log('AddUser-userReducer-useEffect', userAdminReducer);
          const info = userAdminReducer.data.pmscUserData
          if (userAdminReducer.data.isPMSCUser)
            setPMSC(userAdminReducer.data.isPMSCUser);
          setEditReason(userAdminReducer.data.canEditReason ? userAdminReducer.data.canEditReason : false);
          if (info)
            setEmployeeData({
              birthdate: info?.birthdate,
              employeeId: info?.employeeId,
              gender: info?.gender,
              email: info?.email,
              workProfile: info?.workProfile,
              collar: info?.collar,
              onRoll: info?.onRoll,
              applicableForVoucher: info?.applicableForVoucher,
              warehouses: info?.warehouses,
              latitude: info?.latitude,
              longitude: info?.longitude
            });
          setOperatorName(userAdminReducer.data.OperatorName);
          setDesignation(userAdminReducer.data.Designation);
          setEmpType(userAdminReducer.data.EmploymentType);
          setCompany(userAdminReducer.data.CompanyId);
          setMobileno(userAdminReducer.data.MobileNo)
          setPermissionList(userAdminReducer.data.Permission);
          setPermissionInLocal(userAdminReducer.data.Permission);
          setPassword('');
          console.log("AddUser-userReducer-useEffect XX", userAdminReducer.data.isPMSCUser)

          // userAdminReducer.data.
        } else {
          toast({
            title: userAdminReducer?.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
        // if(userAdminReducer.activeCheckbox==="activeCheckbox"){
        // setDesignation(userAdminReducer.data.designation);
        // setEmpType(userAdminReducer.data.empType);
        // setcompany(userAdminReducer.data.longitude);
        // setmobileno(userAdminReducer.data.latitude);
        // setLocalUserId(userAdminReducer.data._id);
        // setIsACtive(activeCheckbox)
        // activeCheckbox
        // setInImei(userAdminReducer.data.IMEI);
        // }else {
        //   toast({
        //     title: userAdminReducer?.message,
        //     status: 'warning',
        //     duration: 2000,
        //     isClosable: true,
        //     position: "top-right",
        //     positionOptions: {
        //       right: "0",
        //       top: "0",
        //     },
        // });
        // }
      } else if (userAdminReducer.action === types.GET_MODULES_SUCCESS) {
        // console.log('LoginScreen-userReducer-useEffect',userReducer)        
        if (userAdminReducer.status === "success") {
          setListModule(userAdminReducer.data);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/Admin/Users');
        } else {
          toast({
            title: userAdminReducer?.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      else if (userAdminReducer.action === types.GET_ROLE_DETAILS_SUCCESS) {
        console.log('GET_ROLE_DETAILS_SUCCESS-useEffect', userAdminReducer)
        if (userAdminReducer.status === "success") {
          // setRoleDesc(userAdminReducer.data.details);
          // setRoleName(userAdminReducer.data.role);
          if (!isLoad) {
            setPermissionList(userAdminReducer.data.permission);
            selModules = [];
            userAdminReducer.data.permission.map((item) => {
              if (item.create) {
                const objModuleC = {
                  "action": "create",
                  "menu": item.moduleName
                }
                selModules.push(objModuleC);
              }
              if (item.view) {
                const objModuleR = {
                  "action": "view",
                  "menu": item.moduleName
                }
                selModules.push(objModuleR);
              }
              if (item.edit) {
                const objModuleU = {
                  "action": "edit",
                  "menu": item.moduleName
                }
                selModules.push(objModuleU);
              }
              if (item.delete) {
                const objModuleD = {
                  "action": "delete",
                  "menu": item.moduleName
                }
                selModules.push(objModuleD);
              }
            })
            console.log('GET_ROLE_DETAILS_SUCCESS-selModules : ', selModules);
          }
          else
            setIsLoad(false);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/Admin/Users');
        } else {
          toast({
            title: userAdminReducer?.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      else if (userAdminReducer.action === types.ADD_USER_REQUEST_FAILURE) {
        toast({
          title: userAdminReducer?.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      } else if (userAdminReducer.action === types.EDIT_USER_REQUEST_FAILURE) {
        toast({
          title: userAdminReducer?.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      }
    }
  }, [userAdminReducer.action])
  useEffect(() => {
    //console.log('Company-companyReducer-useEffect',companyReducer);    
    if (companyReducer.action) {
      if (companyReducer.action === types.GET_COMPANY_REQUEST_SUCCESS) {
        //console.log('Company-companyReducer-useEffect',companyReducer)      
        if (companyReducer.status === "success") {
          setListCompany(companyReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        } else {
          toast({
            title: companyReducer?.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });

        }
      }
    }
  }, [companyReducer.action])
  useEffect(() => {
    // dispatch(getModules());
    if (listRole.length > 0) {
      const role = listRole.filter(item => item.role.toString() == empType.toString());
      // console.log('listRole',listRole);
      // console.log('empType.toString()',empType.toString());
      // console.log('role',role);
      if (role)
        dispatch(getRoleDetails(role[0]?._id));
    }
  }, [empType])
  useEffect(() => {
    // dispatch(getModules());
    if (empType) {
      const role = listRole.filter(item => item.role.toString() == empType.toString());
      // console.log('listRole',listRole);
      // console.log('empType.toString()',empType.toString());
      // console.log('role',role);
      if (role)
        dispatch(getRoleDetails(role[0]._id));
    }
  }, [listRole])
  const submitUser = async () => {
    if (isEmpty(operatorName)) {
      toast({
        title: 'Please enter operator name',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strFNameValMsg);
      return;
    } else if (isEmpty(designation)) {
      toast({
        title: 'Please enter designation',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    } else if (isEmpty(empType)) {
      toast({
        title: 'Please enter employment type',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    } else if (isEmpty(company)) {
      toast({
        title: 'Please enter company',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    } else if (!isValidPhone(mobileno)) {
      toast({
        title: 'Please enter mobile number with 10 or 13 length',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (!localUserId && isEmpty(password)) {
      toast({
        title: 'Please enter password',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      return;
    }

    // console.log('loginRequest',loginRequest);
    { console.log("xxxxxxxx") }
    if (localUserId) {
      let permissionList = [];
      // const request="";
      listModule.map((item, index) => {
        // console.log('item.name',item.name);        
        { console.log("xxx item", item) }
        const modules = selModules.filter((modul, index) => modul.menu == item.name);
        // console.log('modules',modules);
        let actionParam = '';
        let isCreate = false;
        let isEdit = false;
        let isView = false;
        let isDelete = false;
        let isListing = false;
        if (modules[0]) {
          if (modules[0].action == 'create')
            isCreate = true;
          if (modules[0].action == 'edit')
            isEdit = true;
          if (modules[0].action == 'view')
            isView = true;
          if (modules[0].action == 'delete')
            isDelete = true;
        }
        if (modules[1]) {
          if (modules[1].action == 'create')
            isCreate = true;
          if (modules[1].action == 'edit')
            isEdit = true;
          if (modules[1].action == 'view')
            isView = true;
          if (modules[1].action == 'delete')
            isDelete = true;
        }
        if (modules[2]) {
          if (modules[2].action == 'create')
            isCreate = true;
          if (modules[2].action == 'edit')
            isEdit = true;
          if (modules[2].action == 'view')
            isView = true;
          if (modules[2].action == 'delete')
            isDelete = true;
        }
        if (modules[3]) {
          if (modules[3].action == 'create')
            isCreate = true;
          if (modules[3].action == 'edit')
            isEdit = true;
          if (modules[3].action == 'view')
            isView = true;
          if (modules[3].action == 'delete')
            isDelete = true;
        }
        let permission = { 'moduleName': item.name.trim(), create: isCreate, edit: isEdit, view: isView, delete: isDelete, listing: isListing };
        permissionList.push(permission);
      })
      const request = {
        "id": localUserId,
        "OperatorName": operatorName,
        "Designation": designation,
        "EmploymentType": empType,
        "CompanyId": company,
        "MobileNo": mobileno,
        canEditReason,
        "Password": password,
        "userType": 2,
        "Permission": permissionList
      };
      if (isPMSC) {
        request.isPMSCUser = true;
        request.pmscUserData = employeeData;
      }
      if (isPMSC && !validateForm()) {
        toast({
          title: "Validation Error",
          description: "Please fill in all required fields.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      console.log('latest response before');
      dispatch(editUser(request));
      await updateSites()
      console.log("latest response after")
    } else {
      let permissionList = [];
      // const request="";
      listModule.map((item, index) => {
        // console.log('item.name',item.name);        
        const modules = selModules.filter((modul, index) => modul.menu == item.name);
        // console.log('modules',modules);
        let actionParam = '';
        let isCreate = false;
        let isEdit = false;
        let isView = false;
        let isDelete = false;
        let isListing = false;
        if (modules[0]) {
          if (modules[0].action == 'create')
            isCreate = true;
          if (modules[0].action == 'edit')
            isEdit = true;
          if (modules[0].action == 'view')
            isView = true;
          if (modules[0].action == 'delete')
            isDelete = true;
        }
        if (modules[1]) {
          if (modules[1].action == 'create')
            isCreate = true;
          if (modules[1].action == 'edit')
            isEdit = true;
          if (modules[1].action == 'view')
            isView = true;
          if (modules[1].action == 'delete')
            isDelete = true;
        }
        if (modules[2]) {
          if (modules[2].action == 'create')
            isCreate = true;
          if (modules[2].action == 'edit')
            isEdit = true;
          if (modules[2].action == 'view')
            isView = true;
          if (modules[2].action == 'delete')
            isDelete = true;
        }
        if (modules[3]) {
          if (modules[3].action == 'create')
            isCreate = true;
          if (modules[3].action == 'edit')
            isEdit = true;
          if (modules[3].action == 'view')
            isView = true;
          if (modules[3].action == 'delete')
            isDelete = true;
        }
        let permission = { 'moduleName': item.name.trim(), create: isCreate, edit: isEdit, view: isView, delete: isDelete, listing: isListing };
        permissionList.push(permission);
      })
      let newSites = []
      for (const [key, value] of myMap) {
        for (let i = 0; i < value.length; i++) {
          newSites.push(value[i])
        }
      }
      const request = {
        "OperatorName": operatorName,
        "Designation": designation,
        "EmploymentType": empType,
        "CompanyId": company,
        "MobileNo": mobileno,
        canEditReason, 
        "Password": password,
        "userType": 2,
        "Permission": permissionList,
        "AssignedSites": newSites
      };
      console.log('AddUser-request', request);
      if (isPMSC) {
        request.isPMSCUser = true;
        request.pmscUserData = employeeData;
      }
      if (isPMSC && !validateForm() && isValidPhone(mobileno)) {
        toast({
          title: "Validation Error",
          description: "Please fill in all required fields.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      dispatch(addUser(request));
    }
    // navigate('/dashboard');
  }
  const setPermissionInLocal = (permissionParam) => {
    permissionParam.map((item) => {
      if (item.create) {
        const objModuleC = {
          "action": "create",
          "menu": item.moduleName
        }
        selModules.push(objModuleC);
      }
      if (item.view) {
        const objModuleR = {
          "action": "view",
          "menu": item.moduleName
        }
        selModules.push(objModuleR);
      }
      if (item.edit) {
        const objModuleU = {
          "action": "edit",
          "menu": item.moduleName
        }
        selModules.push(objModuleU);
      }
      if (item.delete) {
        const objModuleD = {
          "action": "delete",
          "menu": item.moduleName
        }
        selModules.push(objModuleD);
      }
    })
  }
  const handleProjectInput = (e) => {
    //console.log('AddUser-handleProjectInput-name-',e.target.name+"-value-"+e.target.value);
    if (e.target.name === "txtOptName") {
      setOperatorName(e.target.value);
    }
    if (e.target.name === "txtDesignt") {
      setDesignation(e.target.value);
    }
    if (e.target.name === "txtMobNo") {
      setMobileno(e.target.value);
    }
    if (e.target.name === "txtPassword") {
      setPassword(e.target.value);
    }
  }


  const updateSites = async () => {
    try {
      let newSites = []
      for (const [key, value] of myMap) {
        console.log('xxx new site val', value)
        for (let i = 0; i < value.length; i++) {
          newSites.push(value[i])
        }
      }

      console.log('xxx new sites', newSites)
      // return;
      const data = await apiCallWithPromiseToken(apiName.updateSitesOfUserAPI, { 'userId': localUserId, 'sitesInfo': newSites, 'render': render })
      console.log("latest response", data);
    } catch (error) {
      console.log(error?.message, 'dcsecervrec')
    }
  }
  const handleChangeEvent = (e) => {
    //console.log('AddUser-handleChangeEvent-name-',e.target.name+"-value-"+e.target.value);    
    if (e.target.name === "selEmpType") {
      setEmpType(e.target.value);
    }
    if (e.target.name === "selCompany") {
      setCompany(e.target.value);
    }
  }
  const handleMenuActions = (e) => {
    //console.log('AddRoles-handleMenuActions-name : ',e.target.name+" : value : "+e.target.value+" : default value : "+e.target.defaultChecked+" : checked value : "+e.target.checked);    
    const objModule = {
      "action": e.target.name,
      "menu": e.target.value
    }
    console.log('AddRoles-handleMenuActions-objModule : ', objModule);
    const findModuleIndex = selModules.findIndex((item) => (item.action == e.target.name && item.menu == e.target.value));
    console.log('AddRoles-handleMenuActions-findModuleIndex : ', findModuleIndex);
    if (findModuleIndex > -1) {
      selModules.splice(findModuleIndex, 1);
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////// Logic to check if view permission is removed then need to remove create, edit, delete also /////////////////
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if (e.target.name == "view") {
        const findModuleIndexC = selModules.findIndex((item) => (item.action == "create" && item.menu == e.target.value));
        // console.log('HandleMenuActions-findModuleIndexC : ',findModuleIndexC);
        if (findModuleIndexC > -1) {
          selModules.splice(findModuleIndexC, 1);
        }

        const findModuleIndexE = selModules.findIndex((item) => (item.action == "edit" && item.menu == e.target.value));
        // console.log('HandleMenuActions-findModuleIndexE : ',findModuleIndexE);
        if (findModuleIndexE > -1) {
          selModules.splice(findModuleIndexE, 1);
        }

        const findModuleIndexD = selModules.findIndex((item) => (item.action == "delete" && item.menu == e.target.value));
        // console.log('HandleMenuActions-findModuleIndexD : ',findModuleIndexD);
        if (findModuleIndexD > -1) {
          selModules.splice(findModuleIndexD, 1);
        }
      }
      ////////////////////////////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      // selModules.splice(findModuleIndex,1);
      // console.log('handleMenuActions-findModuleIndex : ',findModuleIndex);
      // console.log('handleMenuActions-selModules : ',selModules);
    }
    else {
      ////////////////////////////////////////////////////////////////////////////
      //////// Logic to check if view permission is exist or not /////////////////
      ////////////////////////////////////////////////////////////////////////////
      if (e.target.name == "create" || e.target.name == "edit" || e.target.name == "delete") {
        const objModuleView = {
          "action": "view",
          "menu": e.target.value
        }
        const findModuleIndex = selModules.findIndex((item) => (item.action == "view" && item.menu == e.target.value));
        if (findModuleIndex == -1) {
          selModules.push(objModuleView);
        }
      }
      selModules.push(objModule);
    }
    // console.log('AddRoles-handleMenuActions-permissionList-1 : ',permissionList);
    setPermissionList([]);
    console.log('AddRoles-handleMenuActions-selModules : ', selModules);
    // console.log('AddRoles-handleMenuActions-permissionList-1 : ',permissionList);
    let permisionLocalList = [];
    listModule.map((item, index) => {
      // console.log('item.name',item.name);        
      let modules = selModules.filter((modul, index) => modul.menu == item.name);
      // console.log('modules',modules);
      modules = removeDuplicates(modules, "action");
      console.log('modules-after-removing-duplicate', modules);
      // let actionParam='';
      let isCreate = false;
      let isEdit = false;
      let isView = false;
      let isDelete = false;
      let isListing = false;
      if (modules[0]) {
        if (modules[0].action == 'create')
          isCreate = true;
        if (modules[0].action == 'edit')
          isEdit = true;
        if (modules[0].action == 'view')
          isView = true;
        if (modules[0].action == 'delete')
          isDelete = true;
      }
      if (modules[1]) {
        if (modules[1].action == 'create')
          isCreate = true;
        if (modules[1].action == 'edit')
          isEdit = true;
        if (modules[1].action == 'view')
          isView = true;
        if (modules[1].action == 'delete')
          isDelete = true;
      }
      if (modules[2]) {
        if (modules[2].action == 'create')
          isCreate = true;
        if (modules[2].action == 'edit')
          isEdit = true;
        if (modules[2].action == 'view')
          isView = true;
        if (modules[2].action == 'delete')
          isDelete = true;
      }
      if (modules[3]) {
        if (modules[3].action == 'create')
          isCreate = true;
        if (modules[3].action == 'edit')
          isEdit = true;
        if (modules[3].action == 'view')
          isView = true;
        if (modules[3].action == 'delete')
          isDelete = true;
      }
      let permission = { 'moduleName': item.name.trim(), create: isCreate, edit: isEdit, view: isView, delete: isDelete, listing: isListing };
      console.log('In Loop-permission : ', permission);
      permisionLocalList.push(permission);
    })
    // console.log('AddRoles-handleMenuActions-permisionLocalList : ',permisionLocalList);
    setPermissionList(permisionLocalList);
    // console.log('AddRoles-handleMenuActions-selModules : ',selModules);
    // console.log('AddRoles-handleMenuActions-permissionList-2 : ',permissionList);
  }
  const fetchUserDetails = () => {
    //console.log('AddUser-fetchUserDetails-userId=',location.state.userId);
    dispatch(getUserDetails(location.state.userId));
  }
  const fetchCompanyList = async () => {
    dispatch(getCompany("page=1&limit=1000"));
  }

  const handleSwitchChange = () => {
    setEmployeeData((prevData) => ({
      ...prevData,
      applicableForVoucher: !prevData.applicableForVoucher,
    }));
  };

  const handleInputChange = (field, value) => {
    setEmployeeData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAddWarehouse = () => {
    setEmployeeData((prevData) => ({
      ...prevData,
      warehouses: [...prevData.warehouses, { name: "", latitude: "", longitude: "" }],
    }));
  };

  const handleWarehouseInputChange = (index, field, value) => {
    setEmployeeData((prevData) => {
      const updatedWarehouses = [...prevData.warehouses];
      updatedWarehouses[index][field] = value;
      return {
        ...prevData,
        warehouses: updatedWarehouses,
      };
    });
  };

  const handleDeleteWarehouse = (index) => {
    setEmployeeData((prevData) => {
      const updatedWarehouses = [...prevData.warehouses];
      updatedWarehouses.splice(index, 1);
      return {
        ...prevData,
        warehouses: updatedWarehouses,
      };
    });
  };



  const addValueInMap = (key, val) => {
    const updatedMap = new Map(myMap);
    if (updatedMap.has(key)) updatedMap.delete(key)
    updatedMap.set(key, val);
    setMyMap(updatedMap);
  }
  const fetchRoleList = async () => {
    dispatch(getRoles());
  }
  const fetchModuleList = async () => {
    dispatch(getModules());
  }

  const handleSelectedOptions = (projects, selectedValues, projectId) => {
    const project = projects.find(proj => proj._id === projectId);
    if (!project) {
      console.error(`Project with ID ${projectId} not found`);
      return;
    }

    const allSiteOptions = convertSitesToOptions(project.sites, projectId);
    const allSitesSelected = selectedValues.includes('all');

    let finalSelectedOptions;
    if (allSitesSelected) {
      finalSelectedOptions = allSiteOptions.filter(option => option.value !== 'all');
    } else {
      finalSelectedOptions = allSiteOptions.filter(option =>
        selectedValues.includes(option.value) && option.value !== 'all'
      );
    }

    console.log('Final Selected Options:', finalSelectedOptions);
    addValueInMap(projectId, finalSelectedOptions);
  };

  const bindProjects = () => {
    if (!listProjects || listProjects.length === 0) return <Text>No projects available</Text>;

    return listProjects
      .filter((item) => isDesire || adminProject.includes(item._id))
      .map((project, index) => {
        if (!project.sites || project.sites.length === 0) return null;

        const options = convertSitesToOptions(project.sites, project._id);
        const defaultValue = selectedSites
          .filter(item => item.projectId === project._id && item.label)
          .map(item => item._id);

        return (
          <Card key={index} style={{ marginBottom: '16px' }}>
            <Text strong style={{ marginBottom: '8px', display: 'block' }}>
              {project.ProjectName} ({project.StateName})
            </Text>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select sites"
              defaultValue={defaultValue}
              onChange={(selectedValues) => handleSelectedOptions(listProjects, selectedValues, project._id)}
            >
              {options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Card>
        );
      });
  };

  const convertSitesToOptions = (sites, projectId) => {
    const options = sites.map(site => ({
      value: site._id,
      label: site.pumpName,
      projectId: projectId,
      aquaId: site.aqualogixID,
      isSelected: updatedUserAssignedSites.includes(site._id)
    }));
    options.unshift({
      value: 'all',
      label: 'All Sites',
      projectId: projectId,
      isAllOption: true
    });
    return options;
  };
  const bindMenus = () => {
    return listModule.map((item, index) => {
      { console.log("xxx this is my item", item) }
      const permission = permissionList.filter(obj => obj.moduleName == item.name);
      return (
        <tr key={index.toString()}>
          <td className="mdl-data-table__cell--non-numeric table_cl">{item.name}</td>
          <td>
            <input
              className="form-check-input"
              type="checkbox"
              id={item._id + '_create'}
              name="create"
              value={item.name}
              checked={permission[0]?.['create']}
              onClick={handleMenuActions}
            />
          </td>
          <td>
            <input
              className="form-check-input"
              type="checkbox"
              id={item._id + '_view'}
              name="view"
              value={item.name}
              // disabled={item.name.includes("Report")}
              checked={permission[0]?.['view']}
              onClick={handleMenuActions}
            />
          </td>
          <td>
            <input
              className="form-check-input"
              type="checkbox"
              id={item._id + '_edit'}
              name="edit"
              // disabled={item.name.includes("Report")}
              value={item.name}
              checked={permission[0]?.['edit']}
              onClick={handleMenuActions}
            />
          </td>
          <td>
            <input
              className="form-check-input"
              type="checkbox"
              id={item._id + '_delete'}
              name="delete"
              // disabled={item.name.includes("Report")}
              value={item.name}
              checked={permission[0]?.['delete']}
              onClick={handleMenuActions}
            />
          </td>
        </tr>

      )
    })
  }
  return (
    <div className="page">
      {console.log("xxx huh selected", selectedSites)}
      {console.log('xxx huh map', myMap)}
      <NavBarAdmin />
      <AdminMenu selMenu="Users" />
      <section>
        {dontShow ?
          <div className="container">
            <div className="row d-flex justify-content-center pt-4 h-100">
              <p className="h2 fw-bold mb-4 ">{localUserId && dontShow ? `Update Sites : ${operatorName}` : localUserId ? "Update User" : "Add User"}</p>

              {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
              <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body pt-5">
                  <div className="form-outline flex-fill mb-0">
                    <label className="form-label" for="form3Example3c">User Name</label>
                    <input type="text" name="txtOptName" id="txtOptName" readOnly width={'50%'} value={operatorName} onChange={(event) => handleProjectInput(event)} className="form-control" />
                  </div>
                  <div style={{ margin: '20px 0' }}>
                    <Divider mt={3} />
                    <Button onClick={() => { setRender(!render) }} colorScheme={'blue'} style={{ display: 'flex', justifyContent: "center", alignItems: 'center', textAlign: 'center' }}>{'Site Assign '} {' '}{!render ? <MdAdd /> : <MdClose />} </Button>
                    <Divider />
                    {/* {render && localUserId &&  (
                          <Box>
                              <Text fontSize="lg" fontWeight='medium' mb="2" mt='4'>
                                Previously Assigned Sites :
                              </Text>
                            <Flex gap={'2'} flexWrap={'wrap'} mt={updatedUserAssignedSites.length > 0 ? '5' : '0'}>
                              {bindPreviouslyAssignedSites()}
                            </Flex>
                          </Box>
                        )} */}
                    <Divider />
                    {/* todo */}
                    <SimpleGrid columns={{ base: 1, md: 1, lg: 3 }} spacing={4}>
                      {render && bindProjects()}
                    </SimpleGrid>
                    <Divider mb={3} />
                    <div className="d-flex justify-content-center mb-3">
                      {/* <button type="button" className="btn btn-primary btn-lg" disabled={isLoading} onClick={doSignin}>{userReducer.loading?<LoadingSpinner />:"Submit"}</button> */}
                      <Link className="btn btn-danger btn-lg btnMarginRight" to={"/Admin/Users"}>Back</Link>
                      <ButtonWithSpinner onClick={() => submitUser()} isLoading={userAdminReducer.loading ? true : false} label={'Save'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="container">
            <div className="row d-flex justify-content-center pt-4 h-100">
              {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
              <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body pt-5">
                  <div className="row justify-content-center">
                    <div className="col-12 order-2 order-lg-1">
                      <p className="h1 fw-bold mb-4 ">{localUserId ? "Update User" : "Add User"}</p>
                      <form className="mx-1 mx-md-2">
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">User Name</label>
                            <input type="text" name="txtOptName" id="txtOptName" value={operatorName} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Designation</label>
                            <input type="text" name="txtDesignt" id="txtDesignt" value={designation} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Company</label>
                            <div className="col-sm-10">
                              <select className="selectpicker form-control" name="selCompany" onChange={(e) => handleChangeEvent(e)}>
                                <option value="">Select Company</option>
                                {listCompany.map((item, index) => (
                                  <option value={item._id} selected={company == item._id ? true : false}>{item.CompanyName}</option>
                                ))}
                                {/* <option value="63c045b6a193a308bca7f581" selected={company=="63c045b6a193a308bca7f581"?true:false}>NCC Company</option> */}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">MSDN/M2M No</label>
                            <input type="text" name="txtMobNo" id="txtMobNo" value={mobileno} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Password</label>
                            <input type="password" name="txtPassword" id="txtPassword" value={password} onChange={(event) => handleProjectInput(event)} className="form-control" />
                            <label className="form-label" for="form3Example3c"><b>Note:</b> Add the password value if you want to update it or else keep it blank.</label>
                          </div>
                        </div>
                        <br />
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Employment Type</label>
                            <div className="col-sm-10">
                              <select className="selectpicker form-control" name="selEmpType" onChange={(e) => handleChangeEvent(e)}>
                                <option value="">Select Employment Type</option>
                                {listRole.map((item, index) => (
                                  <option value={item.role} selected={empType == item.role ? true : false}>{item.role}</option>
                                ))}
                                {/* // <option value="Admin" selected={empType=="Admin"?true:false}>Admin</option>
                                    // <option value="Desire" selected={empType=="Desire"?true:false}>Desire</option>
                                    // <option value="Contractor" selected={empType=="Contractor"?true:false}>Contractor</option>
                                    // <option value="Gov User" selected={empType=="Gov User"?true:false}>Gov User</option>
                                    // <option value="Operator" selected={empType=="Operator"?true:false}>Operator</option> */}
                              </select>
                            </div>
                            {/* <input type="text" name="txtempType" id="txtempType" value={inLatitude} onChange={(event) => handleProjectInput(event)} className="form-control" /> */}
                          </div>
                        </div>
                        <Switch isChecked={isPMSC} onChange={() => {
                          setPMSC(!isPMSC); setEmployeeData({
                            birthdate: "",
                            employeeId: "",
                            gender: "Male",
                            email: "",
                            workProfile: "",
                            collar: "Blue Collar",
                            onRoll: "Desire",
                            applicableForVoucher: false,
                            warehouses: []
                          })
                        }}>PMSC User</Switch>

                        <Switch ml={3} isChecked={canEditReason} onChange={() => {
                          setEditReason(!canEditReason); 
                        }}>Site Inactive Reason Permission</Switch>
                        {isPMSC &&
                          <VStack spacing={4} align="stretch">
                            <Divider />
                            <Box p={4} borderWidth="1px" borderRadius="lg" boxShadow="base">
                              <VStack align="start" spacing={4}>
                                <Text mt={5}>Birthdate</Text>
                                <Input
                                  type="date"
                                  placeholder="Birthdate"
                                  value={employeeData.birthdate}
                                  onChange={(e) => handleInputChange("birthdate", e.target.value)}
                                />

                                <Text>Employee ID</Text>
                                <Input
                                  placeholder="Employee ID"
                                  value={employeeData.employeeId}
                                  onChange={(e) => handleInputChange("employeeId", e.target.value)}
                                />


                                <Text>Email</Text>
                                <Input
                                  placeholder="Email"
                                  value={employeeData.email}
                                  onChange={(e) => handleInputChange("email", e.target.value)}
                                />

                                <Text>Work Profile</Text>
                                <Input
                                  placeholder="Work Profile"
                                  value={employeeData.workProfile}
                                  onChange={(e) => handleInputChange("workProfile", e.target.value)}
                                />
                                <Text>Latitude</Text>
                                <Input
                                  placeholder="Latitude"
                                  value={employeeData.latitude}
                                  onChange={(e) => handleInputChange("latitude", e.target.value)}
                                />
                                <Text>Longitude</Text>
                                <Input
                                  placeholder="Longtitude"
                                  value={employeeData.longitude}
                                  onChange={(e) => handleInputChange("longitude", e.target.value)}
                                />
                                <VStack align="start">
                                  <Flex >
                                    <Text>Gender : </Text>
                                    <HStack ml={3} spacing={4}>
                                      <Radio
                                        value="Male"
                                        isChecked={employeeData.gender === "Male"}
                                        onChange={() => handleInputChange("gender", "Male")}
                                      >
                                        Male
                                      </Radio>
                                      <Radio
                                        value="Female"
                                        isChecked={employeeData.gender === "Female"}
                                        onChange={() => handleInputChange("gender", "Female")}
                                      >
                                        Female
                                      </Radio>
                                      <Radio
                                        value="Other"
                                        isChecked={employeeData.gender === "Other"}
                                        onChange={() => handleInputChange("gender", "Other")}
                                      >
                                        Other
                                      </Radio>
                                    </HStack>
                                  </Flex>
                                </VStack>

                                <VStack align="start">
                                  <Flex>
                                    <Text>Collar : </Text>
                                    <HStack ml={3} spacing={4}>
                                      <Radio
                                        value="Blue Collar"
                                        isChecked={employeeData.collar === "Blue Collar"}
                                        onChange={() => handleInputChange("collar", "Blue Collar")}
                                      >
                                        Blue Collar
                                      </Radio>
                                      <Radio
                                        value="White Collar"
                                        isChecked={employeeData.collar === "White Collar"}
                                        onChange={() => handleInputChange("collar", "White Collar")}
                                      >
                                        White Collar
                                      </Radio>
                                    </HStack>
                                  </Flex>
                                </VStack>

                                <VStack align="start">
                                  <Flex>
                                    <Text>On Roll : </Text>
                                    <HStack ml={3} spacing={4}>
                                      <Radio
                                        value="Desire"
                                        isChecked={employeeData.onRoll === "Desire"}
                                        onChange={() => handleInputChange("onRoll", "Desire")}
                                      >
                                        Desire
                                      </Radio>
                                      <Radio
                                        value="Aqualogix"
                                        isChecked={employeeData.onRoll === "Aqualogix"}
                                        onChange={() => handleInputChange("onRoll", "Aqualogix")}
                                      >
                                        Aqualogix
                                      </Radio>
                                      <Radio
                                        value="3rd Party"
                                        isChecked={employeeData.onRoll === "3rd Party"}
                                        onChange={() => handleInputChange("onRoll", "3rd Party")}
                                      >
                                        3rd Party
                                      </Radio>
                                    </HStack>
                                  </Flex>
                                </VStack>
                                <Box mb={5}>
                                  <Flex alignItems={'center'}>
                                    <Text>Applicable For Voucher:</Text>
                                    <Switch
                                      ml={4}
                                      isChecked={employeeData.applicableForVoucher}
                                      onChange={handleSwitchChange}
                                    />
                                  </Flex>
                                </Box>

                              </VStack>
                            </Box>
                            <Divider />
                            <Text fontWeight={'bold'} mb={2}>WAREHOUSES : </Text>
                            {employeeData?.warehouses?.map((warehouse, index) => (
                              <VStack key={index} align="start" spacing={4} style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }} p={3}>
                                <Text>Warehouse Name</Text>
                                <Input
                                  placeholder="Warehouse Name"
                                  value={warehouse.name}
                                  onChange={(e) => handleWarehouseInputChange(index, "name", e.target.value)}
                                />
                                <Text>Latitude</Text>
                                <Input
                                  placeholder="Latitude"
                                  value={warehouse.latitude}
                                  onChange={(e) => handleWarehouseInputChange(index, "latitude", e.target.value)}
                                />
                                <Text>Longitude</Text>
                                <Input
                                  placeholder="Longitude"
                                  value={warehouse.longitude}
                                  onChange={(e) => handleWarehouseInputChange(index, "longitude", e.target.value)}
                                />
                                <Button colorScheme="red" size="sm" onClick={() => handleDeleteWarehouse(index)}>
                                  Delete Warehouse
                                </Button>
                              </VStack>
                            ))}

                            <Box>
                              <Button colorScheme="teal" onClick={handleAddWarehouse}>
                                Add Warehouse
                              </Button>
                            </Box>
                          </VStack>
                        }
                        {<div style={{ margin: '20px 0' }}>
                          <Divider mt={3} />

                          <Button onClick={() => { setRender(!render) }} colorScheme={'blue'} style={{ display: 'flex', justifyContent: "center", alignItems: 'center', textAlign: 'center' }}>{'Site Assign '} {' '}{!render ? <MdAdd /> : <MdClose />} </Button>
                          <Divider />
                          {/* {render && localUserId &&  (
                          <Box>
                              <Text fontSize="lg" fontWeight='medium' mb="2" mt='4'>
                                Previously Assigned Sites :
                              </Text>
                            <Flex gap={'2'} flexWrap={'wrap'} mt={updatedUserAssignedSites.length > 0 ? '5' : '0'}>
                              {bindPreviouslyAssignedSites()}
                            </Flex>
                          </Box>
                        )} */}
                          <Divider />
                          {/* todo */}
                          <SimpleGrid columns={{ base: 1, md: 1, lg: 3 }} spacing={4}>
                            {render && bindProjects()}
                          </SimpleGrid>
                          <Divider mb={3} />
                        </div>}
                        <div className="d-flex flex-row mb-3">
                          <div className="form-outline flex-fill mb-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="table_cl">Module</th>
                                  <th>Create</th>
                                  <th>View</th>
                                  <th>Update</th>
                                  <th>Delete</th>
                                </tr>
                              </thead>
                              <tbody>{bindMenus()}</tbody>
                            </table>
                          </div>
                        </div>
                        {/* <div className="d-flex flex-row mb-3">
                            <div className="form-outline flex-fill mb-0">
                              <input className="form-check-input me-2" type="checkbox" value="" id="activeCheckbox" onChange={(event)=>handleActiveStatus(event)} />
                              <label className="form-check-label" for="activeCheckbox">Check for make it active</label>
                            </div>
                        </div> */}
                        <div className="d-flex justify-content-center mb-3">
                          {/* <button type="button" className="btn btn-primary btn-lg" disabled={isLoading} onClick={doSignin}>{userReducer.loading?<LoadingSpinner />:"Submit"}</button> */}
                          <Link className="btn btn-danger btn-lg btnMarginRight" to={"/Admin/Users"}>Back</Link>
                          <ButtonWithSpinner onClick={() => submitUser()} isLoading={userAdminReducer.loading ? true : false} label={'Save'} />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>}
      </section>
    </div>
  );
};

export default AddUser;