import React, { useState, useEffect } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Select, Box } from '@chakra-ui/react';
import NavBarAdmin from './NavBarAdmin';
import HourlyReport from '../Pages/HourlyReport';
import Report from '../Pages/Report';
import Mqttdata from './MqttdataComponent';
import Assetdata from '../Adminpanel/Reports/Assetdata';
import UserAndSiteReports from './UserAndSiteReports';
import Pumpactivity from '../Adminpanel/Reports/Pumpactivity';
import ActivityReport from '../Adminpanel/Reports/ActivityReport';
import { getData, CONSTANTVALUE } from '../utils';
import { getCompleteAssignedSitesByUserIdAPI, getSiteListWithProjectsAPI } from '../store/apis';
import PumpactivityReport from '../Adminpanel/Reports/PumpActivityReport';

const AllReports = () => {
  const [projects, setProjects] = useState([]);
  const [assignedSites, setAssignedSites] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Monitoring Reports");

  const fetch = async () => {
    const data = await getData(CONSTANTVALUE.USEROBJECT);
    const info = await getSiteListWithProjectsAPI(data.id);
    const filteredProjects = info.message.filter((item) => item.project && item.sites.length > 0);
    setProjects(filteredProjects);
    const sites = await getCompleteAssignedSitesByUserIdAPI(data.id);
    setAssignedSites(sites.message.map((item) => item.result));
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // Updated tab configuration with new categories and tab names
  const tabGroups = {
    "Monitoring Reports": [
      { label: "Hourly Reports", component: <HourlyReport noHeaders={true} projectList={projects} assignedSites={assignedSites} /> },
      { label: "MQTT Reports", component: <Mqttdata noHeaders={true} projectList={projects} newAssignedSites={assignedSites} /> },
      
    ],
    "Daily Performance Reports": [
      { label: "Daily Reports", component: <Report noHeaders={true} projectList={projects} assignedSites={assignedSites} /> },
      { label: "Activity Status Reports", component: <ActivityReport noHeaders={true} projectList={projects} newAssignedSites={assignedSites} /> },
      { label: "Pump Activity Reports", component: <PumpactivityReport noHeaders={true} projectList={projects} newAssignedSites={assignedSites} /> },
    ],
    "Resource Management Reports": [
      { label: "Asset Reports", component: <Assetdata noHeaders={true} projectList={projects} newAssignedSites={assignedSites} /> },
      { label: "DG Consumption Reports", component: <Pumpactivity noHeaders={true} projectList={projects} newAssignedSites={assignedSites} /> },
    ],
    "Site Reports": [
      { label: "User Site Reports", component: <UserAndSiteReports noHeaders={true} /> },
    ],
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <NavBarAdmin />
      <Box mt="120px" px={4}>
        <Flex justify="space-between" align="center" mb={4}>
          <Select value={selectedCategory} onChange={handleCategoryChange} width="200px">
            <option value="Monitoring Reports">Monitoring Reports</option>
            <option value="Daily Performance Reports">Daily Performance Reports</option>
            <option value="Resource Management Reports">Resource Management Reports</option>
            <option value="Site Reports">Site Reports</option>
          </Select>
        </Flex>
        <Tabs variant="enclosed">
          <TabList>
            {tabGroups[selectedCategory].map((tab, index) => (
              <Tab key={index}>{tab.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabGroups[selectedCategory].map((tab, index) => (
              <TabPanel key={index}>{tab.component}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </div>
  );
};

export default AllReports;
