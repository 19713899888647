import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';

const containerStyle = {
    width: '100%',
    height: '600px'
};

const InnerMapComponent = ({ siteReducer }) => {
    const [lat, setLat] = useState(0);
    const [siteName, setSiteName] = useState("");
    const [lng, setLng] = useState(0); // 'lng' for longitude

    useEffect(() => {
        if (siteReducer && siteReducer.length > 0) {
            setLat(parseFloat(siteReducer[0].latitude)); // Ensure it's a number
            setLng(parseFloat(siteReducer[0].longitude)); // Ensure it's a number
            setSiteName(siteReducer[0].pumpName);
        }
    }, [siteReducer]);

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: 'AIzaSyC_mmyHAnkTF9dUjcTngzxG9eHfS3oMbfM', // Replace with a valid API key
    });

    const center = {
        lat: lat, // Ensure this is a number
        lng: lng // Ensure this is a number
    };


    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
        >
            {console.log("INNER MAPPPP", lat, lng, siteName)}
            <Marker
                position={center}
                label={{
                    text:  typeof siteName == 'string' && siteName.length > 0 ? siteName : "Loading..", // Label for the marker
                    fontSize: '14px', // Adjust font size as needed
                    fontWeight: 'bold',
                }}
            />
        </GoogleMap>
    ) : (
        <div>Loading Map...</div>
    );
};

export default InnerMapComponent;
