import React from 'react';
import PropTypes from "prop-types";
import { MdInfoOutline, MdLabelImportant, MdCheckCircle, MdCancel } from 'react-icons/md';
import { Box, IconButton, Tooltip, List, ListItem } from '@chakra-ui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const SensorValue = ({ data, assets, isChlorine }) => {

  const getIf = (label) => (label === 'Fuel Level of DG' ? '%' : '');

  const getRelevantAssetData = (label) => {
    switch (label) {
      case 'OHSR level':
        return assets.levelSensor;
      case 'Motor Voltage':
      case 'Motor Current':
      case 'Motor Frequency':
      case 'Power':
        return assets.motor;
      case 'Flow Rate':
        return assets.flowMeter;
      case 'Pressure':
        return assets.pressureMeter;
      default:
        return null;
    }
  };

  const formatAssetDataTooltip = (asset) => {
    return (
      <List spacing={2} styleType="disc">
        {Object.entries(asset).map(([key, value]) => {
          if (key === 'id') return null; // Exclude 'id'
          
          // Format date values and set missing values to "-"
          if (key.includes('Date') && value) {
            value = dayjs(value).tz('Asia/Kolkata').format('DD-MM-YYYY HH:mm:ss');
          } else if (!value) {
            value = '-';
          }
  
          return (
            <ListItem ml={2} key={key}>
              <strong>{key.replace(/([A-Z])/g, ' $1')}:</strong> {value}
            </ListItem>
          );
        })}
      </List>
    );
  };
  
  if (data.length > 0) {
    return (
      <div className="row">
        <div className="col mb-4">
          <div className="card">
            <div className="card-body p-0">
              <div className="row py-3 py-md-0">
                <div className="col-12 col-md-12 col-xl-12">
                  <div className="row">
                    {data.map((item, index) => {
                      const relevantAsset = item.label === 'Chlorine' ? null : getRelevantAssetData(item.label);
                      const isChlorineLabel = item.label === 'Chlorine';

                      return (
                        <div key={index} className="col-12 col-md-6 col-xl-2">
                          <div className="box-content border-start position-relative">
                            {/* Show Chlorine doser status icon aligned to the right */}
                            {isChlorineLabel && (
                              <Tooltip
                                label={isChlorine ? "Chlorine doser is on" : "Chlorine doser is off"}
                                fontSize="sm"
                                bg="gray.700"
                                color="white"
                                placement="top"
                                hasArrow
                              >
                                <IconButton
                                  icon={isChlorine ? <MdCheckCircle color="green" /> : <MdCancel color="red" />}
                                  size="sm"
                                  position="absolute"
                                  top="8px"
                                  right="8px" // Aligns to the right
                                  aria-label="Chlorine doser status"
                                />
                              </Tooltip>
                            )}

                            {/* Display asset tooltip if available and label is not Chlorine */}
                            {relevantAsset && (
                              <Tooltip
                                label={<Box p={2}>{formatAssetDataTooltip(relevantAsset)}</Box>}
                                fontSize="sm"
                                bg="gray.700"
                                color="white"
                                placement="top"
                                hasArrow
                              >
                                <IconButton
                                  icon={<MdInfoOutline />}
                                  size="sm"
                                  position="absolute"
                                  top="8px"
                                  right="8px"
                                  aria-label="Relevant to asset"
                                />
                              </Tooltip>
                            )}

                            <h4 className="card-header-title mb-2 mb-sm-0" style={{ height: '65%' }}>
                              <i className="icon-map">
                                <img src={item.imgIc} alt={item.value} style={{ height: '40px', width: '40px' }} />
                              </i>
                              {item.label}
                            </h4>
                            <p className="site-section-name" style={{ height: '35%', color: '#3182CE' }}>
                              {item.value + " " + getIf(item.label)}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

SensorValue.propTypes = {
  data: PropTypes.array.isRequired,
  assets: PropTypes.object.isRequired,
  isChlorine: PropTypes.bool,  // Add isChlorine prop type
};

SensorValue.defaultProps = {
  data: [],
  assets: {},
  isChlorine: false,
};

export default SensorValue;
