import {getData} from './storage'
import {isEmpty, isEmptyObj} from './validator'
import {CONSTANTVALUE} from './constants'
export const isLoggedInUser=async()=>{
    const userObject=await getData(CONSTANTVALUE.USEROBJECT);
    const userToken=await getData(CONSTANTVALUE.USERTOKEN);
    if(!isEmptyObj(userObject) || !isEmpty(userToken))
        return true;
    else    
        return false;
}
export const getLoggedInUser=async()=>{
    const userObject=await getData(CONSTANTVALUE.USEROBJECT);
    if(!isEmptyObj(userObject))
        return userObject;
    else    
        return null;
}

export const checkPermission=async(module,type)=>{
    const userObject=await getData(CONSTANTVALUE.USEROBJECT);
    // console.log('checkPermission-userObject',userObject);
    let isPermission=false;
    if(!isEmptyObj(userObject)){
        if(userObject.permission){
            console.log("PErmiSSIONS", userObject.permission)
            userObject.permission?.map((item,index)=>{
                // console.log('checkPermission-item.moduleName',item.moduleName);
                // console.log('checkPermission-module',module);
                if(item.moduleName==module){
                    // console.log('checkPermission-item[type]',module,item[type]);
                    return isPermission=item[type]?true:false;
                }                    
            })
        }        
    }
    // console.log('checkPermission-isPermission',isPermission);
    return isPermission;
}
export const sortArrayData=(a, b)=>{
    return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
}
export const removeDuplicates=(array,prop)=>{
      
    // Declare a new array
    let newArray = [];
      
    // Declare an empty object
    let uniqueObject = {};
      
    // Loop for the array elements
    for (let i in array) {
      
        // Extract the title
        let objTitle = array[i][prop];
      
        // Use the title as the index
        uniqueObject[objTitle] = array[i];
    }
      
    // Loop to push unique object into array
    for (let i in uniqueObject) {
        newArray.push(uniqueObject[i]);
    }      
    
    // Display the unique objects
    return newArray;
}
export const getSortOrder=(prop)=>{
     return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 