import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { getMqttData } from '../redux/actions/siteAction'
import ButtonWithSpinner from "../Components/ButtonWithSpinner";
import { types } from '../redux/actions/types';
import * as XLSX from 'xlsx';

import { Box, Select, Flex, background } from '@chakra-ui/react';
// import NavBarAdmin from "../Components/NavBarAdmin";
// import AdminMenu from "../Components/AdminMenu";
import { siteList } from '../redux/actions/siteAdminAction'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInUser, checkPermission, getData, CONSTANTVALUE } from '../utils'
import { IconButton, Tooltip, useToast } from '@chakra-ui/react';

import { AiFillFileExcel, AiFillPrinter, AiOutlineSearch as SearchIcon } from 'react-icons/ai'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Link as ChakraLink,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { apiGetCallWithPromise } from '../store/axiosApicall';
import { apiName, serverPath } from '../store/serverConfig';
import NavBarAdmin from './NavBarAdmin';
import AdminMenu from './AdminMenu';
import { MdDeviceHub } from 'react-icons/md';
import axios from 'axios';
import { mqttDataAPI } from '../store/apis';

// import angleLeft from "../assets/images/down-arrow.svg";
// import { leftCanvasToggle } from "../redux/reducers/dashboardSlice";
// import { Link } from "react-router-dom";
// import "./offCanvas/offCanvas.css";
// import CanvasItem from "./offCanvas/CanvasItem";
// import PropTypes from "prop-types";

let reportTimeout = null;
const Mqttdata = ({ noHeaders, projectList, newAssignedSites }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var intervalId = null;
  const [data, setMqttData] = useState([]);
  const [imeino, setImeino] = useState('');
  const [pageSize, setPageSize] = useState(200);
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast()
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const [reportDate, setReportDate] = useState(new Date());
  // const { isLeftCanvas } = useSelector((state) => state.dashboard);

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  // const [isCreate, setIsCreate] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  // const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  useEffect(()=> {
    const p = localStorage.getItem('selectedProject');
    setTimeout(()=> {
      if(p) {
        setSelectedProject(p)
      }
    }, 2000)
  }, [])
  const [selectedSites, setSelectedSites] = useState(newAssignedSites);
  const [site, setSite] = useState('')

  const exportToExcel = () => {
    if (data.length > 0) {
      const worksheetData = data.map(item => ({
        IMEI: item.IMEI || '',
        P1: item.P1 ? item.P1.toFixed(2) : 0,
        P2: item.P2 ? item.P2.toFixed(2) : 0,
        P3: item.P3 ? item.P3.toFixed(2) : 0,
        P4: item.P4 ? item.P4.toFixed(2) : 0,
        P5: item.P5 ? item.P5.toFixed(2) : 0,
        P6: item.P6 ? item.P6.toFixed(2) : 0,
        P7: item.P7 ? item.P7.toFixed(2) : 0,
        P8: item.P8 ? item.P8.toFixed(2) : 0,
        P9: item.P9 ? item.P9.toFixed(2) : 0,
        P10: item.P10 ? item.P10.toFixed(2) : 0,
        P11: item.P11 ? item.P11.toFixed(2) : 0,
        P12: item.P12 ? item.P12.toFixed(2) : 0,
        P13: item.P13 ? item.P13.toFixed(2) : 0,
        P14: item.P14 ? item.P14.toFixed(2) : 0,
        P15: item.P15 ? item.P15.toFixed(2) : 0,
        P16: item.P16 ? item.P16.toFixed(2) : 0,
        P17: item.P17 ? item.P17.toFixed(2) : 0,
        P18: item.P18 ? item.P18.toFixed(2) : 0,
        P19: item.P19 ? item.P19.toFixed(2) : 0,
        Date: moment(item.deviceDateTime).format("DD/MM/YYYY h:mm:ss a"),
        RoutingKey: item.RoutingKey || ''
      }));
  
      const worksheet = XLSX.utils.json_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'MQTT Data');
      XLSX.writeFile(workbook, `Mqtt_Report_${moment(reportDate).format("DD-MM-YYYY")}.xlsx`);
    }
  };
  

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderTableRows = () => {

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + (pageSize * 2);
    const currentRecords = data.slice(startIndex, endIndex);
    return currentRecords.length > 0 ? (
      currentRecords
        // .filter((item) => {
        //   const itemDate = new Date(item.DTIME).toISOString().split('T')[0];
        //   const reportDateOnly = new Date(reportDate).toISOString().split('T')[0];
        //   // console.log("hihih");
        //   // console.log("item date",itemDate);
        //   // console.log("reportdate",reportDateOnly);
        //   return itemDate === reportDateOnly;
        //   // return true;
        // })
        ?.map((item, index) => {
          { console.log('xxxx item', item) }
          if (
            item.P1 > 0 ||
            item.P2 > 0 ||
            item.P3 > 0 ||
            item.P4 > 0 ||
            item.P5 > 0 ||
            item.P6 > 0 ||
            item.P7 > 0 ||
            item.P8 > 0 ||
            item.P9 > 0 ||
            item.P10 > 0 ||
            item.P11 > 0 ||
            item.P12 > 0 ||
            item.P13 > 0 ||
            item.P14 > 0 ||
            item.P15 > 0 ||
            item.P16 > 0 ||
            item.P17 > 0 ||
            item.P18 > 0 ||
            item.P19 > 0
          ) {
            return (
              <>
                <Tr key={index}>
                  <Td>{item.IMEI ? item.IMEI.toString() : ""}</Td>
                  <Td>{item.P1 ? item.P1.toFixed(2) : 0}</Td>
                  <Td>{item.P2 ? item.P2.toFixed(2) : 0}</Td>
                  <Td>{item.P3 ? item.P3.toFixed(2) : 0}</Td>
                  <Td>{item.P4 ? item.P4.toFixed(2) : 0}</Td>
                  <Td>{item.P5 ? item.P5.toFixed(2) : 0}</Td>
                  <Td>{item.P6 ? item.P6.toFixed(2) : 0}</Td>
                  <Td>{item.P7 ? item.P7.toFixed(2) : 0}</Td>
                  <Td>{item.P8 ? item.P8.toFixed(2) : 0}</Td>
                  <Td>{item.P9 ? item.P9.toFixed(2) : 0}</Td>
                  <Td>{item.P10 ? item.P10.toFixed(2) : 0}</Td>
                  <Td>{item.P11 ? item.P11.toFixed(2) : 0}</Td>
                  <Td>{item.P12 ? item.P12.toFixed(2) : 0}</Td>
                  <Td>{item.P13 ? item.P13.toFixed(2) : 0}</Td>
                  <Td>{item.P14 ? item.P14.toFixed(2) : 0}</Td>
                  <Td>{item.P15 ? item.P15.toFixed(2) : 0}</Td>
                  <Td>{item.P16 ? item.P16.toFixed(2) : 0}</Td>
                  <Td>{item.P17 ? item.P17.toFixed(2) : 0}</Td>
                  <Td>{item.P18 ? item.P18.toFixed(2) : 0}</Td>
                  <Td>{item.P19 ? item.P19.toFixed(2) : 0}</Td>
                  <Td>{moment(item.deviceDateTime).format("DD/MM/YYYY h:mm:ss a")}</Td>
                  <Td>{item.RoutingKey ? item.RoutingKey.toString() : ""}</Td>
                </Tr>
              </>
            );
          } else {
            return (
              <tr key={index}>
                <td colSpan={22}></td>
              </tr>
            );
          }
        })
    ) : (
      <tr>
        <Td colSpan={22}>No Record Found</Td>
      </tr>
    );
  };


  const renderPagination = () => {
    const totalPages = Math.ceil(data.length / pageSize);
    return (
      <ul className="pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
          <li
            key={pageNumber}
            className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
            onClick={() => handlePageChange(pageNumber)}
          >
            <button className="page-link">{pageNumber}</button>
          </li>
        ))}
      </ul>
    );
  };

  const fontFamily = 'Work Sans'

  const handlePrint = () => {
    if (tableRef.current) {
      const printWindow = window.open('Mqtt_Report', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
   <html>
     <head>
       <title>Mqtt_Report</title>
       <h3>${moment(reportDate).format("DD-MM-YYYY")}</h3>
       <style>
         /* Add your custom styles here */
         table {
           border-collapse: collapse;
           width: 100%;
         }
         th, td {
           border: 1px solid #ccc;
           padding: 8px;
           text-align: left;
         }
         th {
           background-color: #f2f2f2;
         }
         td.align-right {
          text-align: right;
      }
       </style>
     </head>
     <body>
       <table style="font-family: ${fontFamily}">
         ${tableRef.current.innerHTML}
       </table>
       <script>
       document.querySelectorAll('td').forEach(cell => {
           if (!isNaN(cell.innerText) && cell.innerText.trim() !== '') {
               cell.classList.add('align-right');
           }
       });
       </script>
     </body>
   </html>
 `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  };
  const navRef = React.useRef(null);
  useEffect(() => {
    chkLogin();
    setPermission();
  }, [])


  const chkLogin = async () => {
    const isLoggedIn = await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if (!isLoggedIn) {
      navigate('/Admin');
    }
  }
  const setPermission = async () => {
    // setIsCreate(await checkPermission('Projects','create'));
    // setIsEdit(await checkPermission('Projects','edit'));
    // setIsDelete(await checkPermission('Projects','delete'));
    setIsView(await checkPermission('MQTT Report', 'view'));
    // console.log('Projects-User Permissions-isCreate='+isCreate+'-isEdit-'+isEdit+'-isDelete-'+isDelete+'-isView-'+isView);     
  }
  // const fetchSiteList=async()=>{
  //   //console.log("fetchSiteList");
  //   dispatch(siteList("page=1&limit=10000"));
  // }
  const fetchMqttData = async () => {
    setLoading(true)

    if (imeino) {
      try {
        console.log("repporrttttDATE", moment(reportDate).format("DD-MM-YYYY"));
        const response = await axios.get(`${serverPath}logs/logsList?page=1&limit=100&imei=${imeino}&date=${reportDate}`);
        console.log('response', response.data);
        setMqttData(response.data);

        // const data = dispatch(getMqttData(`page=1&limit=100&imei=${imeino}&date=${reportDate}`));

      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false)

      }
    }

  }

  const handleUserInput = (e) => {
    // console.log('e.target.name',e.target.name);
    // console.log('e.target.value',e.target.value);
    if (e.target.name === "imeino") {
      setImeino(e.target.value);
    }
    // clearInterval(intervalId);
  }
  useEffect(() => {
    // Fetch sites only if the selected project is not 'ALL'
    if (selectedProject !== 'ALL') {
      const selectedProjectData = projectList?.find((item) => item.project._id === selectedProject);
      setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
    } else {
      // Clear sites when 'All Projects' is selected
      setSelectedSites([]);
    }
  }, [selectedProject]);
  const handleSiteChange = (event) => {

    const selectedSiteName = event.target.options[event.target.selectedIndex].text;
    const selectedSiteIMEI = event.target.options[event.target.selectedIndex].getAttribute('imei');

    // console.log('Selected Site Name:', selectedSiteName);
    setSite(selectedSiteName);
    // console.log('Selected Site IMEI:', selectedSiteIMEI);
    setImeino(selectedSiteIMEI);
    console.log('Selected Site IMEI:', imeino);
  };
  const handleChangeEvent = (e) => {
    //console.log('AddUser-handleChangeEvent-name-',e.target.name+"-value-"+e.target.value);    
    if (e.target.name === "selSite") {
      // fetchProjectList();
      setImeino(e.target.value);
    }
  }
  const bindSites = () => {
    if (newAssignedSites) {
      // console.log('bindProjects1-listProjects',listProjects);
      if (newAssignedSites.length > 0) {
        // console.log('bindProjects2-listProjects',listProjects);
        return (newAssignedSites.map((item, index) => (
          <option key={index} value={item.IMEI}> {item.pumpName.toString() + "   (IMEI " + item.IMEI + ")"} </option>

        )))
      }
    }
  }
  const bindData = () => {
    return (
      <div className={noHeaders ? null : "page"}>
        {!noHeaders && <NavBarAdmin />}
        {!noHeaders && <AdminMenu selMenu="MqttReports" />}
        <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginBottom: '50px' }}>
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head" style={{ display: "flex", alignItems: 'center' }} >MQTT Reports <MdDeviceHub color='#2b6cb0' style={{ marginLeft: '7px' }} /> </div>
              <h3 className="project-title fw-bold">IoT sensor data, including pressure and water quality metrics.</h3>
            </div>
          </div>
          <div />
        </div>
        <section>
          <div className='container'>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
              <div>
                <Flex >
                  <Select
                    id="pageSizeSelect"
                    value={selectedProject}
                    variant={'filled'}
                    onChange={(e) => setSelectedProject(e.target.value)}
                    color="black"
                  >
                    <option value={'ALL'}>Select Project</option>
                    {projectList?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                      <option key={item.project._id} value={item.project._id}>
                        {item.project.ProjectName}
                      </option>
                    ))}
                  </Select>
                  {selectedProject !== 'ALL' && (
                    <Select
                      // w={'15%'}
                      variant={'filled'}
                      ml={2}
                      // value={site}
                      onChange={(e) => handleSiteChange(e)}

                      color="black"
                    >
                      <option value={'ALL'}>Select Site</option>
                      {selectedSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                        <option key={site._id} value={site._id} imei={site.IMEI}>
                          {site.pumpName}
                        </option>
                      ))}
                    </Select>
                  )}
                </Flex>
              </div>
              <div><input type="date" className="form-control" name="inputReportDate" value={reportDate} onChange={(e) => setReportDate(e.target.value)} style={{ width: '100%' }} /></div>
              <div>
                <Tooltip hasArrow label='Data will refresh every 5 seconds' bg='facebook.600' placement='right-end'>
                  <IconButton
                    isLoading={loading}
                    colorScheme='blue'
                    w={'50px'}
                    ml={'8px'}
                    mb={'6px'}
                    aria-label='Search database'
                    icon={<SearchIcon />}
                    size='md' onClick={() => {
                      fetchMqttData();
                    }}
                  />
                </Tooltip>
              </div>
              <label htmlFor="pageSizeSelect" className="form-label" style={{ marginLeft: '15px' }}>
                Page Size:
              </label>
              <select
                id="pageSizeSelect"
                className="form-select"
                value={pageSize}
                style={{ width: '13%', marginLeft: "10px" }}
                onChange={handlePageSizeChange}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
              <div style={{ marginLeft: '10px' }}>Page:</div>
              <div className="mx-2">{currentPage}</div>
            </div>
          </div>
        </section>

        <div className="col-6 col-md-6">
          <div className="row mb-3">
            <div className="row">
              <div className="col">
                <div className="d-flex flex-row">
                  {/* Any additional content for this section */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='table-responsive'>
          <Table variant='striped' colorScheme='gray' size="sm" tableLayout="fixed" ref={tableRef}>
            <Thead>
              <Tr>
                <Th w="5%">IMEI</Th>
                <Th w="5%">Pressure</Th>
                <Th w="5%">Level Sensor</Th>
                <Th w="5%">Depth Sensor</Th>
                <Th w="5%">Chlorine</Th>
                <Th w="5%">Turbidity</Th>
                <Th w="5%">VFD Motor Voltage</Th>
                <Th w="5%">VFD Motor Current</Th>
                <Th w="5%">Solar KWH</Th>
                <Th w="5%">VFD MOTOR FREQ</Th>
                <Th w="5%">SOLAR VOLTAGE</Th>
                <Th w="5%">VFD MOTOR KW</Th>
                <Th w="5%">Flow Rate</Th>
                <Th w="5%">Totalizer</Th>
                <Th w="5%">Fuel Level</Th>
                <Th w="5%">DG RUNNING HOUR</Th>
                <Th w="5%">DG RUNNING MIN</Th>
                <Th w="5%">DG KW</Th>
                <Th w="5%">DG KWH</Th>
                <Th w="5%">DG CURRENT</Th>
                <Th w="10%">Date</Th>
                <Th w="10%">RoutingKey</Th>
              </Tr>
            </Thead>
            <Tbody>
              {renderTableRows()}
            </Tbody>
          </Table>
          <div className="row d-flex justify-content-center mt-3 mb-5" style={{ marginLeft: "10px" }}>
            {renderPagination()}
          </div>
        </div>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
          <Button mt='15px' colorScheme='blue' style={{ display: 'flex', gap: '5px' }} onClick={handlePrint}>
            Print
            <AiFillPrinter />
          </Button>
          <Button
            mt='15px'
            colorScheme='blue'
            style={{ display: 'flex', gap: '5px' }}
            onClick={exportToExcel}
          >
            Export to Excel
            <AiFillFileExcel />
          </Button>
        </div>
      </div>
    )
  }
  const noViewPermission = () => {
    return (<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <>
      {isView ? bindData() : noViewPermission()}
    </>
  );
};

// Mqttaata.propTypes = {
//     projectId: PropTypes.number,
// };
// Mqttaata.defaultProp = {
//     projectId: null,
// };
export default Mqttdata;