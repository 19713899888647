import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Input, Select, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useToast } from '@chakra-ui/react';
import validatePayload from 'z-validify';

const { Option } = Select;

const EmailMaster = ({ projects }) => {
    const toast = useToast();
    const [mails, setMails] = useState([]);
    const [visible, setVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedFrequency, setSelectedFrequency] = useState([]);
    const [editingMail, setEditingMail] = useState(null);

    useEffect(() => {
        fetchMails();
    }, []);

    const fetchMails = async () => {
        try {
            const response = await axios.get('http://mqtt.aqualogix.in:85/master/getMails');
            setMails(response.data.allMails);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleAddEmail = () => {
        setVisible(true);
        fetchMails();
    };

    const handleOk = async () => {
        try {
            const { isValid, message } = validatePayload({ email: email.trim(), selectedProjects, frequency: selectedFrequency });
            if (!isValid) {
                toast({
                    title: 'Invalid Fields',
                    description: message,
                    position: 'top-right',
                    status: 'error',
                    duration: 3000
                });
                return;
            }

            if (editingMail) {
                await axios.post(`http://mqtt.aqualogix.in:85/master/updateMail`, { 
                    email: email.trim(), 
                    projectArray: selectedProjects, 
                    frequency: selectedFrequency, 
                    id: editingMail._id 
                });
                toast({
                    title: 'Email Updated Successfully',
                    position: 'top-right',
                    status: 'success',
                    duration: 3000
                });
            } else {
                await axios.post('http://mqtt.aqualogix.in:85/master/addMail', { 
                    email: email.trim(), 
                    projectArray: selectedProjects, 
                    frequency: selectedFrequency 
                });
                toast({
                    title: 'Email Saved Successfully',
                    position: 'top-right',
                    status: 'success',
                    duration: 3000
                });
            }

            fetchMails();
        } catch (error) {
            console.log(error.message);
        } finally {
            setVisible(false);
            setEmail('');
            setSelectedProjects([]);
            setSelectedFrequency([]);
            setEditingMail(null);
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setEditingMail(null);
    };

    const handleEdit = (record) => {
        setEditingMail(record);
        setEmail(record.email);
        setSelectedProjects(record.projectArray);
        setSelectedFrequency(record.frequency || []);
        setVisible(true);
    };

    const handleProjectsChange = (value) => {
        setSelectedProjects(value);
    };

    const handleFrequencyChange = (value) => {
        setSelectedFrequency(value);
    };

    const deleteEmail = async (email) => {
        try {
            await axios.get(`http://mqtt.aqualogix.in:85/master/deleteMail?email=${email}`);
            toast({
                title: 'Email Deleted Successfully',
                position: 'top-right',
                status: 'success',
                duration: 3000
            });
            fetchMails();
        } catch (error) {
            console.log(error.message);
        }
    };

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Projects',
            dataIndex: 'projectArray',
            key: 'projectArray',
            render: (text, record) => (
                <Select
                    mode="multiple"
                    value={record.projectArray}
                    onChange={handleProjectsChange}
                    style={{ width: '100%' }}
                >
                    {projects.map(project => (
                        <Option key={project?.project?._id} value={project?.project?.ProjectName}>
                            {project?.project?.ProjectName}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            render: (text) => (Array.isArray(text) ? text.join(', ') : 'N/A'),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div style={{display : "flex"}}>
                    <Button 
                        icon={<EditOutlined />} 
                        onClick={() => handleEdit(record)} 
                        type="link"
                        style={{ marginRight: '8px' }}
                    />
                    <Popconfirm
                        title="Are you sure to delete this email?"
                        onConfirm={() => deleteEmail(record.email)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button 
                            icon={<DeleteOutlined />} 
                            type="link" 
                            danger 
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div>
            <Button onClick={handleAddEmail} mt={5} colorScheme="teal" mb={4}>
                Add Email
            </Button>
            <Table dataSource={mails} style={{ marginTop: "30px" }} columns={columns} rowKey="_id" />

            <Modal
                title={editingMail ? "Edit Email" : "Add Email"}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Input
                    placeholder="Enter email"
                    value={email}
                    style={{ marginTop: "30px" }}
                    onChange={e => setEmail(e.target.value)}
                />
                <Select
                    mode="multiple"
                    placeholder="Select projects"
                    value={selectedProjects}
                    onChange={handleProjectsChange}
                    style={{ width: '100%', marginTop: '30px' }}
                >
                    {projects.map(project => (
                        <Option key={project?.project?._id} value={project?.project?.ProjectName}>
                            {project?.project?.ProjectName}
                        </Option>
                    ))}
                </Select>
                <Select
                    mode="multiple"
                    placeholder="Select frequency"
                    value={selectedFrequency}
                    onChange={handleFrequencyChange}
                    style={{ width: '100%', marginTop: '30px' }}
                >
                    <Option value="Daily">Daily</Option>
                    <Option value="Weekly">Weekly</Option>
                </Select>
            </Modal>
        </div>
    );
};

export default EmailMaster;
