import React, { useEffect, useState } from 'react';
import { Select, Flex, IconButton, Table, Thead, Tbody, Tr, Th, Td, Input, Spinner, Button } from '@chakra-ui/react';
import { MdDeviceHub } from 'react-icons/md';
import { AiOutlineSearch } from 'react-icons/ai';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { CONSTANTVALUE, getData } from '../../utils';

const ActivityReport = ({ noHeaders, projectList }) => {
    const [reportDate, setReportDate] = useState(new Date());
    const [selectedProject, setSelectedProject] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [siteFilter, setSiteFilter] = useState('ALL');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const p = localStorage.getItem('selectedProject');
        if (p) setSelectedProject(p);
    }, []);

    useEffect(() => {
        // Apply filtering when search term or site filter changes
        let filtered = data;

        // Filter by pump name
        if (searchTerm) {
            filtered = filtered.filter((item) =>
                item.pumpName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Filter by site status
        if (siteFilter !== 'ALL') {
            filtered = filtered.filter((item) => item.status === siteFilter);
        }

        setFilteredData(filtered);
    }, [searchTerm, siteFilter, data]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const user = await getData(CONSTANTVALUE.USEROBJECT);
            const response = await axios.post('http://mqtt.aqualogix.in:85/report/getActivityStatusReport', {
                projectID: selectedProject,
                userId: user.id,
                reportDate
            });
            setData(response.data.message);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredData); // Convert data to worksheet
        const workbook = XLSX.utils.book_new();  // Create a new workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Activity Report');  // Append worksheet
        XLSX.writeFile(workbook, `Activity_Report_${new Date().toISOString()}.xlsx`);  // Write the file and prompt download
    };


    return (
        <div className={noHeaders ? null : "page"}>
            <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginBottom: '50px' }}>
                <div className="row m-0">
                    <div className="col-12 py-4">
                        <div className="sub-head" style={{ display: "flex", alignItems: 'center' }}>
                            Activity Status Reports <MdDeviceHub color='#2b6cb0' style={{ marginLeft: '7px' }} />
                        </div>
                        <h3 className="project-title fw-bold">Shows site activity status—active or inactive</h3>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="row d-flex justify-content-center h-100 pt-5">
                        <div className="card text-black" style={{ borderRadius: "10px" }}>
                            <div className="card-body">
                                <Flex justify="space-between" align="center" gap="20px">
                                    {/* Left side - Project filter, date, search button */}
                                    <Flex gap="10px" align="center">
                                        <Select
                                            id="pageSizeSelect"
                                            value={selectedProject}
                                            variant="filled"
                                            onChange={(e) => setSelectedProject(e.target.value)}
                                            color="black"
                                            placeholder="Select Project"
                                        >
                                            {projectList?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                                                <option key={item.project._id} value={item.project._id}>
                                                    {item.project.ProjectName}
                                                </option>
                                            ))}
                                        </Select>

                                        <input
                                            type="date"
                                            className="form-control"
                                            name="inputReportDate"
                                            value={reportDate.toISOString().substr(0, 10)}
                                            onChange={(e) => setReportDate(new Date(e.target.value))}
                                            style={{ width: '100%' }}
                                        />

                                        <IconButton
                                            colorScheme='blue'
                                            w={'50px'}
                                            aria-label='Search database'
                                            icon={loading ? <Spinner /> : <AiOutlineSearch />}
                                            size='md'
                                            onClick={fetchData}
                                            isDisabled={!selectedProject || loading}
                                        />
                                    </Flex>

                                    {/* Right side - Site filter and pump name search */}
                                    <Flex gap="10px" align="center">
                                        <Select
                                            id="siteFilter"
                                            value={siteFilter}
                                            onChange={(e) => setSiteFilter(e.target.value)}
                                            placeholder="Filter by Active/Inactive"
                                            variant="filled"
                                        >
                                            <option value="ALL">All</option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Select>

                                        <Input
                                            placeholder="Search by Pump Name"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </Flex>
                                </Flex>

                                {/* Chakra UI Table */}
                                <Table variant="striped" colorScheme="gray" size="md" mt={5}>
                                    <Thead>
                                        <Tr>
                                            <Th>IMEI</Th>
                                            <Th>Pump Name</Th>
                                            <Th>Status</Th>
                                            <Th>Reason</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {filteredData.map((item, index) => (
                                            <Tr key={index}>
                                                <Td>{item.IMEI}</Td>
                                                <Td>{item.pumpName}</Td>
                                                <Td>{item.status}</Td>
                                                <Td>{item.status === 'Inactive' ? item.reason : '-'}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <Flex justify="flex-end" mt={4}>
                                    <Button colorScheme="blue" mt={2} onClick={handleExportToExcel}>
                                        Export to Excel
                                    </Button>
                                </Flex>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ActivityReport;
