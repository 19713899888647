import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { types } from '../../redux/actions/types';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import DateRangePicker from '../../Components/DateRangePicker';
import { siteList } from '../../redux/actions/siteAdminAction'
import { AiFillFileExcel, AiFillPrinter, AiOutlineSearch as SearchIcon } from 'react-icons/ai';
import { useRef } from 'react';
import * as XLSX from 'xlsx';


// import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInUser, checkPermission, CONSTANTVALUEEXTRAPARAM, getSortOrder, isEmpty, getData, CONSTANTVALUE } from '../../utils'
// import {getSiteExtraParameterAPI,updateSiteExtraParameterAPI} from '../../store/apis';
import { getSiteExtraParameterAPI, updateSiteExtraParameterAPI, getCountryAPI, getStateAPI, getDistrictAPI, getBlockAPI, getPumpActivityAPI } from '../../store/apis';
import { Button, useToast, IconButton, Center, Tooltip, ModalOverlay, ModalHeader, ModalCloseButton, ModalFooter, ModalBody, ModalContent, Modal } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Flex, Select
} from '@chakra-ui/react';
import { apiGetCallWithPromise } from '../../store/axiosApicall';
import { apiName } from '../../store/serverConfig';
import { MdArrowDownward, MdArrowUpward, MdAutoGraph, MdWaterDrop } from 'react-icons/md';
import MyTimeline from './CalendarTimeline';
import axios from 'axios';

var moment = require('moment');

// let reportTimeout=null;
const PumpactivityReport = ({ noHeaders, projectList, newAssignedSites }) => {
  var totalDuration = 0;
  const navigate = useNavigate()
  var totalProduction = 0;
  var dgEnergyConsumptionTotal = 0;
  const reportReducer = useSelector(state => state.reportReducer);
  const [projectsList, setProjects] = useState(projectList);
  const [selectedProject, setSelectedProject] = useState('');
  useEffect(() => {
    const p = localStorage.getItem('selectedProject');
    setTimeout(() => {
      if (p) {
        setSelectedProject(p)
      }
    }, 2000)
  }, [])
  const [site, setSite] = useState('')
  const [dynamicData, setDynamicData] = useState([]);
  const [selectedSites, setSelectedSites] = useState(newAssignedSites);

  const generateDummyData = (selectedSiteName) => {
    const dates = [];
    let currDate = new Date(startDate);
    while (currDate <= endDate) {
      dates.push(new Date(currDate));
      currDate.setDate(currDate.getDate() + 1);
    }
    console.log("DATES", dates);
  
    // Generate data for each date and selected site
    const data = dates.flatMap(date => 
      selectedSites
        .filter(site => selectedSiteName == null || selectedSiteName === 'ALL' || selectedSiteName === site.pumpName)
        .map(site => ({
          siteName: site.pumpName,
          reportDate: date.toISOString().split('T')[0], // current date in the loop
          startTime: '08:00:00',
          endTime: '16:00:00',
          sourceMode: Math.random() > 0.5 ? 'Auto' : 'Manual',
          openingKWH: Math.random() * 1000 + 1000, // random opening KWH
          closingKWH: Math.random() * 1000 + 2000, // random closing KWH
          totalKWH: parseFloat((Math.random() * 20).toFixed(1)), // random total KWH
        }))
    );
  
    console.log("DATES", data);
    setDynamicData([]);
  };
  

  // Trigger data generation on project or site selection change
  
  
  const [imeino, setImeino] = useState();
  const [siteId, setSiteId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [blockId, setBlockId] = useState(0);
  const [reportDate, setReportDate] = useState(new Date().toISOString().slice(0, 10));
  const [districtSite, setDistrictSite] = useState([]);
  const [blockSite, setBlockSite] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [pumpActivityList, setPumpActivityList] = useState([]);
  const [pumpObject, setPumpObject] = useState(null);
  const [pumpSerialNo, setPumpSerialNo] = useState('');
  const [motorObject, setMotorObject] = useState(null);
  const [motorSerialNo, setMotorSerialNo] = useState('');
  const [isModalOpen, setModalOpen] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const state = useSelector(store => store.userReducer);
  useEffect(() => {
    generateDummyData();
  }, [selectedProject, selectedSites]);
  
  const [flowMeterObject, setFlowMeterObject] = useState(null);
  const [flowMeterSerialNo, setFlowMeterSerialNo] = useState('');
  const [pressureMeterObject, setPressureMeterObject] = useState(null);
  const [pressureMeterSerialNo, setPressureMeterSerialNo] = useState('');
  const [multiFuncMeterObject, setMultiFuncMeterObject] = useState(null);
  const [multiFuncMeterSerialNo, setMultiFuncMeterSerialNo] = useState('');
  const [phSensorObject, setPHSensorObject] = useState(null);
  const [pHSensorSerialNo, setPHSensorSerialNo] = useState('');
  const [lvlSensorObject, setLvlSensorObject] = useState(null);
  const [lvlSensorSerialNo, setLvlSensorSerialNo] = useState('');
  const [trbtMonObject, setTrbtMonObject] = useState(null);
  const [trbtMonSerialNo, setTrbtMonSerialNo] = useState('');
  const [clrnSensorObject, setClrnSensorObject] = useState(null);
  const [clrnSensorSerialNo, setClrnSensorSerialNo] = useState('');
  const [totalRunningHours, setTotalRunningHours] = useState('');
  const [actuatorObject, setActuatorObject] = useState([]);
  const [actuatorSerialNo, setActuatorSerialNo] = useState([]);
  const [assetDetails, setAssetDetails] = useState(null);
  const [actuatorIds, setActuatorIds] = useState([]);
  const [backupList, setBackupList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [selCountry, setSelCountry] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [selState, setSelState] = useState(null);
  const [districtList, setDistrictList] = useState([]);
  const [selDistrict, setSelDistrict] = useState(null);
  const [blockList, setBlockList] = useState([]);
  const [selBlock, setSelBlock] = useState(null);
  const [assignedSites, setAssignedSites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dir, setDir] = useState(0);
  const tableRef = useRef(null);


  const [isView, setIsView] = useState(false);

  const exportToExcel = () => {
    if (tableRef.current) {
      const tableData = tableRef.current.innerHTML;
      const worksheet = XLSX.utils.table_to_sheet(tableRef.current);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'MQTT Data');

      // Export the data to a file
      XLSX.writeFile(workbook, `Pump_Activity_Report ${moment(reportDate).format("DD-MM-YYYY")}.xlsx`);
    }
  };

  const fontFamily = 'Work Sans'

  const handlePrint = () => {
    if (tableRef.current) {
      const printWindow = window.open('Pump Activity Report', '_blank');
      printWindow.document.open();
      printWindow.document.write(`

   <html>
     <head>
       <title>DG Consumption</title>
       <h4>${moment(reportDate).format("DD-MM-YYYY")}</h4>
       <style>
         /* Add your custom styles here */
         table {
           border-collapse: collapse;
           width: 100%;
         }
         th, td {
           border: 1px solid #ccc;
           padding: 8px;
           text-align: left;
         }
         th {
           background-color: #f2f2f2;
         }
         td.align-right {
          text-align: right;
        }
       </style>
     </head>
     <body>
       <table style="font-family: ${fontFamily}">
         ${tableRef.current.innerHTML}
       </table>
       <script>
              
       document.querySelectorAll('td').forEach(cell => {
         if (!isNaN(cell.innerText) && cell.innerText.trim() !== '') {
           cell.classList.add('align-right');
         }
       });
     </script>
     </body>
   </html>
 `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  };

  useEffect(() => {
    // Fetch sites only if the selected project is not 'ALL'
    if (selectedProject !== 'ALL') {
      const selectedProjectData = projectList?.find((item) => item.project._id === selectedProject);
      setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
    } else {
      // Clear sites when 'All Projects' is selected
      setSelectedSites([]);
    }
  }, [selectedProject]);

  const handleSiteChange = (event) => {

    const selectedSiteName = event.target.options[event.target.selectedIndex].text;
    const selectedSiteIMEI = event.target.options[event.target.selectedIndex].getAttribute('imei');
    generateDummyData(selectedSiteName)

    // console.log('Selected Site Name:', selectedSiteName);
    setSite(selectedSiteName);
    // console.log('Selected Site IMEI:', selectedSiteIMEI);
    setImeino(event.target.value);
    console.log('Selected Site IMEI:', imeino);
  };


  // const navRef = React.useRef(null);
  useEffect(() => {
    chkLogin();
    setPermission();
  }, [])


  useEffect(() => {
    console.log('alertttttt')
  }, [backupList])
  useEffect(() => {
    console.log('reportReducer', reportReducer);
    if (reportReducer.action) {
      if (reportReducer.action === types.GET_PUMP_ACTIVITY_SUCCESS) {
        if (reportReducer.status === "success") {
          // console.log('reportReducer',reportReducer)
          setBackupList(reportReducer.data.list);
          setPumpActivityList(reportReducer.data.list);
          console.log('xxx iii')
          backupList = reportReducer.data.list;
          console.log('xxx iii 2')
          console.log("This is after", backupList);

        } else {
          toast({
            title: reportReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      else if (reportReducer.action === types.GET_PUMP_ACTIVITY_FAILURE) {
        toast({
          title: `Error Occured : ${reportReducer.message}`,
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      }
    }
  }, [reportReducer.action])
  useEffect(() => {
    if (selCountry) {
      getStateAPI("countryId=" + selCountry).then((response) => {
        // console.log('getStateAPI-response',response);
        if (response)
          setStateList(response.data);
      })
    }
  }, [selCountry])
  useEffect(() => {
    if (selState) {
      getDistrictAPI("stateId=" + selState).then((response) => {
        console.log('getDistrictAPI-response', response);
        if (response)
          setDistrictList(response.data);
      })
    }
  }, [selState])
  useEffect(() => {
    if (selDistrict) {
      getBlockAPI("districtId=" + selDistrict).then((response) => {
        // console.log('getBlockAPI-response',response);
        if (response)
          setBlockList(response.data);
      })
    }
  }, [selDistrict])
  const toast = useToast();

  const chkLogin = async () => {
    const isLoggedIn = await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if (!isLoggedIn) {
      navigate('/Admin');
    }
  }
  const setPermission = async () => {
    console.log('setPermission-isView', isView);
    setIsView(await checkPermission('MQTT Report', 'view'));
    // setIsView(true);
  }
  const fetchSiteList = async () => {
    fetchCountryList();
  }
  const fetchPumpActivityList = async () => {

    console.log('reportDate', moment(reportDate).format("DD-MM-YYYY"));
    generateDummyData()

    // toast({
    //   title: 'Please select site',
    //   status: 'warning',
    //   duration: 2000,
    //   isClosable: true,
    //   position: "top-right",
    //   positionOptions: {
    //     right: "0",
    //     top: "0",
    //   },
    // })
    // return;
    if (isEmpty(startDate) || isEmpty(endDate)) {
      toast({
        title: 'Please select date',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      return;
    }
    if (isEmpty(reportDate)) {
      toast({
        title: 'Please select date',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      return;
    }
    else if (!imeino || imeino == null || imeino == 'ALL') {
      const data = await getData(CONSTANTVALUE.USEROBJECT);
      console.log("DATAAATATAATTA", data);
      setIsLoading(true);
      // const info = await axios.get(`http://mqtt.aqualogix.in:85/report/getAllSitePumpReports?userId=${data.id}&&date=${reportDate}&&projectID=${selectedProject}&&startDate=${moment(startDate).format("YYYY-MM-DD")}&&endDate=${moment(endDate).format("YYYY-MM-DD")}`)
      // console.log("DVCSDVCSD", info.data.response.data.list);
      setIsLoading(false);
      // setPumpActivityList(info.data.response.data.list);

      // Set this data to pumpActivityList
      // setPumpActivityList(dummyData);


      return;
    }
    else {
      setIsLoading(true)
      try {
        const data = await getPumpActivityAPI("id=" + imeino + "&date=" + moment(reportDate).format("YYYY-MM-DD") + "&startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD"));
        if (data && data.data && data.data.list) { setBackupList(data.data.list); setPumpActivityList(data.data.list) }
        console.log('new backup', backupList)

        if (data.data.list.length == 0) {
          toast({
            title: 'No Data Found',
            status: 'info',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          })
        } else {
          toast({
            title: 'Data fetched !',
            status: 'success',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          })

        }
      }
      catch (error) {
        toast({
          title: `Error : ${error.messsage}`,
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        })

      }
      finally {

        setIsLoading(false);
      }
      // districtId=645f2b93ad710d49581e00d4&blockId=645f2b93ad710d49581e00d4
    }
  }


  const compareNumeric = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  useEffect(() => {
    sortData()
  }, [dir])

  const sortData = () => {
    if (dir === 1) {
      // Clone the original array before sorting
      const sortedList = [...pumpActivityList];
      sortedList.sort((a, b) => compareNumeric(b.totalProduction, a.totalProduction));
      setPumpActivityList(sortedList);
    } else if (dir === 2) {
      // Clone the original array before sorting
      const sortedList = [...pumpActivityList];
      sortedList.sort((a, b) => compareNumeric(a.totalProduction, b.totalProduction));
      setPumpActivityList(sortedList);
    } else {
      // When dir is 0, reset to the original order (backupList)
      setPumpActivityList(backupList);
    }
  };
  const handleChangeEvent = (e) => {

    // console.log('name',e.target.name);
    // console.log('value',e.target.value);
    if (e.target.name === "selSite") {
      setSiteId(e.target.value);
    }
    if (e.target.name === "inputReportDate") {
      setReportDate(e.target.value);
    }
    if (e.target.name === "selDistrict") {
      setSelDistrict(e.target.value);
    }
    if (e.target.name === "selBlock") {
      setBlockId(e.target.value);
    }
    if (e.target.name === "selCountry") {
      // console.log('selCountry-e',countryList.find((item)=>item._id==e.target.value).name);
      setSelCountry(e.target.value);
    }
    if (e.target.name === "selState") {
      setSelState(e.target.value);
    }
    if (e.target.name === "selBlock") {
      setSelBlock(e.target.value);
    }
  }
  const bindSites = () => {
    if (newAssignedSites) {
      // console.log('bindProjects1-listProjects',listProjects);
      if (newAssignedSites.length > 0) {
        // console.log('bindProjects2-listProjects',listProjects);
        return (newAssignedSites.map((item, index) => (
          <option key={index} value={item._id}>{item.pumpName.toString() + "   (IMEI " + item.IMEI + ")"}</option>
        )))
      }
    }
  }
  const fetchCountryList = async () => {
    getCountryAPI().then((response) => {
      if (response)
        setCountryList(response.data);
      if (response && response.length > 0)
        setSelCountry(response.data[0]._id);
    })
  }
  function convertMinutesToHours(minutes) {
    if (typeof minutes !== "number" || minutes < 0) {
      throw new Error("Input must be a positive number of minutes.");
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60);
    const seconds = Math.round((minutes - (hours * 60 + remainingMinutes)) * 60);

    return `${hours}:${String(remainingMinutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  }

  function convertToNormalDateFormat(dateTimeString) {
    const date = new Date(dateTimeString);

    // Convert to IST (UTC+5:30)
    const offsetInMilliseconds = 5.5 * 60 * 60 * 1000;
    const istDate = new Date(date.getTime() + offsetInMilliseconds);

    const hours = istDate.getUTCHours();
    const minutes = istDate.getUTCMinutes();
    const seconds = istDate.getUTCSeconds();

    // Determine AM or PM
    const amOrPm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12;

    // Create the normal date format string with AM/PM
    const normalDateFormat = `${formattedHours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")} ${amOrPm}`;
    return normalDateFormat;
  }

  useEffect(() => {
    calTotalHours();
  }, [pumpActivityList])

  const calTotalHours = () => {
    let total = 0;
    // console.log("HIHIH",)
    for (let i = 0; i < pumpActivityList.length; i++) {
      total += pumpActivityList[i].runningTimeInMinutes;
    }
    setTotalRunningHours(convertMinutesToHours(total));
  }

  const changeDir = () => {

    setDir((dir + 1) % 3);
  }
  const openTheModal = () => {

    if (pumpActivityList.length == 0) {

      toast({
        title: "No data to visualize!",
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
    } else {
      setModalOpen(true)
    }
  }
  const rightAlignStyle = {
    textAlign: 'right'
  };


  const bindData = () => {
    return (
      <div className="panel_contentmqttdata">
        {console.log("new backup : ", backupList)}
        <div className="row mt-3 mb-3 align-items-center">
          <div className="col-10">
            <div className="pupmactivity-header" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                <DateRangePicker class="form-control" setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} displayFormat="DD-MM-YYYY" endDate={endDate} />

                {/* <input type="date" class="form-control" name="inputReportDate" value={reportDate} onChange={(e) => handleChangeEvent(e)} /> */}
              </div>
              <div className="form-outline flex-fill mb-0">
                <Flex >
                  <Select
                    id="pageSizeSelect"
                    value={selectedProject}
                    variant={'filled'}
                    onChange={(e) => setSelectedProject(e.target.value)}
                    color="black"
                  >
                    <option value={'ALL'}>All Project</option>
                    {projectList?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                      <option key={item.project._id} value={item.project._id}>
                        {item.project.ProjectName}
                      </option>
                    ))}
                  </Select>
                  {selectedProject !== 'ALL' && (
                    <Select
                      variant={'filled'}
                      ml={2}
                      onChange={(e) => handleSiteChange(e)}
                      color="black"
                    >
                      <option value={'ALL'}>All Site</option>
                      {selectedSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                        <option key={site._id} value={site._id} imei={site.IMEI}>
                          {site.pumpName}
                        </option>
                      ))}
                    </Select>
                  )}
                </Flex>
              </div>
            </div>
          </div>
          <div className="col">
            <Button mr={2} onClick={() => openTheModal()}>Timeline <MdAutoGraph /></Button>

            <IconButton
              colorScheme='blue'
              aria-label='Search database'
              icon={<SearchIcon />}
              w='50px'
              size='md' onClick={() => fetchPumpActivityList()} isLoading={isLoading}
            />
          </div>
        </div>
        <div className='table-responsive'>
          <Table variant='striped' colorScheme='gray' size='sm' ref={tableRef}>
            <Thead>
              <Tr>
                <Th scope='col'>Site</Th>
                <Th scope='col'>Date</Th>
                <Th scope='col'>Start Time</Th>
                <Th scope='col'>End Time</Th>
                <Th scope='col'>Source Mode</Th>
                <Th scope='col'>Running Hours HH:MM:SS</Th>
                {/* <Th scope='col'>Flow Meter Opening Reading (L)</Th> */}
                {/* <Th scope='col'>Flow Meter Closing Reading (L)</Th> */}
                {/* <Th scope='col' display={'flex'} alignItems={'center'} onClick={() => changeDir()}>
                  <Tooltip label='Click to Sort'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Total Production (L){' '}
                      {dir === 1 ? <MdArrowUpward size='25px' /> : dir === 2 ? <MdArrowDownward size='25px' /> : null}
                    </div>
                  </Tooltip>
                </Th> */}
                <Th scope='col'>Opening KWH </Th>
                <Th scope='col'>Closing KWH </Th>
                <Th scope='col'>KWH reading</Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* {bindPumpActivity()} */}

              {dynamicData.length > 0 ? dynamicData.sort((a, b) => a.siteName.localeCompare(b.siteName))
                .map((item, index) => {
                  totalDuration = totalDuration || 0;
                  totalProduction = totalProduction || 0;
                  dgEnergyConsumptionTotal = dgEnergyConsumptionTotal || 0;

                  dynamicData.forEach(item => {
                    // 1. Parse and convert running hours to total seconds
                    const runningDuration = item.runningHoure
                      ? moment.duration(item.runningHoure, 'H:mm:ss').asSeconds()
                      : 0; // Default to 0 if runningHoure is missing or invalid
                    totalDuration += runningDuration;

                    // 2. Add to totalProduction, defaulting to 0 if item.totalProduction is undefined
                    totalProduction += item.totalProduction || 0;

                    // 3. Add to dgEnergyConsumptionTotal, defaulting to 0 if item.totalKWH is "nil" or missing
                    dgEnergyConsumptionTotal += (item.totalKWH && item.totalKWH !== "nil") ? parseFloat(item.totalKWH) : 0;

                    // Optional: Log the item for debugging
                    console.log("bindPumpActivity", item);
                  });

                  // Convert totalDuration back to HH:mm:ss format if needed for display
                  const totalRunningHours = moment.utc(totalDuration * 1000).format('HH:mm:ss');
                  console.log("Total Running Hours:", totalRunningHours);
                  return (<Tr key={index}>
                    <Td>{item.siteName}</Td>
                    <Td>{item.reportDate}</Td>
                    <Td>{moment(item.startTime, "HH:mm:ss").format("hh:mm A")}</Td>
                    <Td>{moment(item.endTime, "HH:mm:ss").format("hh:mm A")}</Td>

                    {/* Calculate and display Running Hours */}
                      <Td>{item.sourceMode}</Td>
                    <Td>
                      {moment
                        .utc(moment(item.endTime, "HH:mm:ss").diff(moment(item.startTime, "HH:mm:ss")))
                        .format("HH:mm:ss")}
                    </Td>
                    {/* <Td style={rightAlignStyle}>{item.flowMeterOpening}</Td>
                  <Td style={rightAlignStyle}>{item.flowMeterClosing}</Td>
                  <Td style={rightAlignStyle}>{item.totalProduction}</Td> */}
                    <Td style={rightAlignStyle}>{item.openingKWH}</Td>
                    <Td style={rightAlignStyle}>{item.closingKWH}</Td>
                    <Td style={rightAlignStyle}>{item.totalKWH.toFixed(2)}</Td>
                  </Tr>
                  )
                }) : <Tr>
                <Td colSpan={11}><Center>No Record Found</Center></Td>
              </Tr>}
            </Tbody>
            {/* <Tfoot mt={4}> */}
              {/* <Tr> */}
                {/* <Th colSpan={7}></Th> */}
                {/* <Th scope='col'>Total Running Hours</Th> */}
              {/* </Tr> */}
              {/* <Tr> */}
                {/* <Th colSpan={7}></Th> */}
                {/* <Td scope='col'>{totalRunningHours}</Td> */}
                {/* <Td scope='col'>{totalProduction}</Td> */}
              {/* </Tr> */}
            {/* </Tfoot> */}
          </Table>
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
            <Button mt='15px' colorScheme='blue' style={{ display: 'flex', gap: '5px' }} onClick={handlePrint}>
              Print
              <AiFillPrinter />
            </Button>
            <Button
              mt='15px'
              colorScheme='blue'
              style={{ display: 'flex', gap: '5px' }}
              onClick={exportToExcel}
            >
              Export to Excel
              <AiFillFileExcel />
            </Button>
          </div>
        </div>
      </div>
    )
  }
  const noViewPermission = () => {
    return (<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (

    <div className={noHeaders ? null : "page"}>
      {console.log("dirrrr", dir)}
      {backupList.length > 0 && console.log("huhn dd", backupList[0].totalProduction)}
      {!noHeaders && <NavBarAdmin />}
      {!noHeaders && <AdminMenu selMenu="PumpActivityReport" />}
      <Modal size={"3xl"} blockScrollOnMount={false} isOpen={isModalOpen} onClose={() => setModalOpen(false)} motionPreset='scale'>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>
            Time-Line:
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MyTimeline pumpActivityList={pumpActivityList} reportDate={reportDate} />
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop: '10px' }}>
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head" style={{ display: "flex", alignItems: 'center' }}>Pump Activity Report
              <MdWaterDrop color='#2b6cb0' style={{ marginLeft: '7px' }} />
            </div>
            <h3 className="project-title fw-bold">Pump On/Off activity report, including running hours.</h3>
          </div>
        </div>
      </div>
      <section>
        {/* {isView?bindData():noViewPermission()}    */}
        <div className="container">
          <div className="row d-flex justify-content-center h-100 pt-5">
            <div className="card text-black" style={{ borderRadius: "10px" }}>
              <div className="card-body">
                {isView ? bindData() : noViewPermission()}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

// Mqttaata.propTypes = {
//     projectId: PropTypes.number,
// };
// Mqttaata.defaultProp = {
//     projectId: null,
// };
export default PumpactivityReport;