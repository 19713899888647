import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, deleteUser } from '../../redux/actions/userAdminAction'
import { types } from '../../redux/actions/types';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import { isEmpty, isLoggedInUser, checkPermission } from '../../utils'
import { Box, Flex, Input, InputGroup, InputLeftElement, Select, useToast } from '@chakra-ui/react';
import { AiFillFileExcel, AiOutlineSearch as SearchIcon } from 'react-icons/ai';
import * as XLSX from 'xlsx';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Link as ChakraLink,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { AiFillPrinter } from 'react-icons/ai';
import { useRef } from 'react';

const User = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast()
  // const [listSite, setListSite] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const tableRef = useRef(null);

  const [isCreate, setIsCreate] = useState(true);
  const [isEdit, setIsEdit] = useState(true);
  const [isDelete, setIsDelete] = useState(true);
  const [isView, setIsView] = useState(true);
  const [selectedDesignation, setSelectedDesignation] = useState('');
  const [selectedEmploymentType, setSelectedEmploymentType] = useState('');
  const [usersPermission, setUserPermissions] = useState({
    isCreate: false,
    isEdit: false,
    isDelete: false,
    isView: false
  });

  const uniqueDesignations = Array.from(new Set(listUser.map(user => user.Designation)));
  const uniqueEmploymentTypes = Array.from(new Set(listUser.map(user => user.EmploymentType)));


  const [searchValue, setSearchValue] = useState('')

  // const [selectedId, setSelectedId] = useState(0);
  // const [isLoading, setIsLoading] = useState(false);
  const userAdminReducer = useSelector(state => state.userAdminReducer);
  useEffect(() => {
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    chkLogin();
    setPermission();
  }, [])
  useEffect(() => {
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    if (isView)
      fetchUsersList();
  }, [isView])
  useEffect(() => {
    console.log('User-userAdminReducer-useEffect', userAdminReducer);
    if (userAdminReducer.action) {
      if (userAdminReducer.action === types.GET_USERS_REQUEST_SUCCESS) {
        console.log('User-userAdminReducer-useEffect', userAdminReducer)
        if (userAdminReducer.status === "success") {
          setListUser(userAdminReducer.data.list);

        } else {
          toast({
            title: userAdminReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      else if (userAdminReducer.action === types.DELETE_USER_REQUEST_SUCCESS) {
        console.log('User-userAdminReducer-useEffect', userAdminReducer)
        if (userAdminReducer.status === "success") {
          fetchUsersList();
        } else {
          toast({
            title: userAdminReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
    }
  }, [userAdminReducer.action])

  const chkLogin = async () => {
    const isLoggedIn = await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if (!isLoggedIn) {
      navigate('/Admin');
    }
  }
  
  const setPermission = async () => {
    
    setUserPermissions({
      iscreate: await checkPermission("Users", 'create'),
      isEdit: await checkPermission("Users", 'edit'),
      isDelete: await checkPermission("Users", 'delete'),
      isView: await checkPermission("Users", 'view')
    })
  }
  const fetchUsersList = async () => {
    // console.log("fetchSiteList");
    dispatch(getUsers("page=" + page + "&limit=" + limit + ""));
  }
  const handleDeleteEvent = (item) => {
    if (window.confirm("Are you sure you want to delete?")) {
      // setTimeout(() => {
      deleteSelUser(item._id);
      // }, 1000);
      console.log("You pressed OK!");
    } else {
      console.log("You pressed Cancel!");
    }
  }
  const fontFamily = 'Work Sans'
  const handlePrint = () => {
    if (tableRef.current) {

      const tableClone = tableRef.current.cloneNode(true);

      const rows = tableClone.rows;
      for (let row of rows) {
        if (row.cells.length > 0) {
          row.deleteCell(-1);
        }
      }

      const printWindow = window.open('UserListing', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>UserListing</title>
            <style>
              /* Add your custom styles here */
              table {
                border-collapse: collapse;
                width: 100%;
              }
              th, td {
                border: 1px solid #ccc;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
              td.align-right {
                text-align: right;
            }
            </style>
          </head>
          <body>
            <table style="font-family: ${fontFamily}">
              ${tableClone.innerHTML}
            </table>
            <script>
            document.querySelectorAll('td').forEach(cell => {
                if (!isNaN(cell.innerText) && cell.innerText.trim() !== '') {
                    cell.classList.add('align-right');
                }
            });
            </script>
          </body>

        </html>
      `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  };

  const handleEditEvent = (item) => {
    console.log('User', item);
    navigate('/Admin/AddUser', {
      state: {
        userId: item._id,
        userName: item.OperatorName,
      }
    });
    // navigate('/Admin/AddSite');
  }
  // const deleteItemClick=(item)=>{
  //   setNewsObject(item);
  //   console.log(item);
  // }
  const exportToExcel = () => {
    if (tableRef.current) {
      const table = tableRef.current;


      const rows = table.querySelectorAll('tr');
      rows.forEach(row => {
        if (row.children.length > 0) {
          row.removeChild(row.lastElementChild);
        }
      });


      const worksheet = XLSX.utils.table_to_sheet(table);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'MQTT Data');

      rows.forEach(row => {
        const cell = row.insertCell(-1);
        cell.innerHTML = row.lastElementChild.dataset.originalContent || '';
      });

      XLSX.writeFile(workbook, 'Users_List.xlsx');
    }
  };
  const deleteSelUser = async (selectedId) => {
    console.log("deleteSelUser-selectedId", selectedId);
    dispatch(deleteUser(selectedId));
  }
  const bindData = () => {
    return (

      <TableContainer>
        <Table variant='striped' colorScheme='gray' ref={tableRef}>
          <Thead>
            <Tr>
              <Th fontSize={'md'}>User Name</Th>
              <Th fontSize={'md'}>Designation</Th>
              <Th fontSize={'md'}>Employment Type</Th>
              <Th fontSize={'md'}>Mobile No</Th>
              <Th fontSize={'md'}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {listUser.length > 0 ? (
              listUser
              .filter(item => !selectedDesignation || item.Designation === selectedDesignation)
              .filter(item => !selectedEmploymentType || item.EmploymentType === selectedEmploymentType)
              .filter((item) => item.OperatorName.toString().toLowerCase().includes(searchValue.toLowerCase())).map((item, index) => (
                <Tr key={index}>
                  <Td>{item.OperatorName.toString()}</Td>
                  <Td>{item.Designation.toString()}</Td>
                  <Td>{item.EmploymentType.toString()}</Td>
                  <Td>{item.MobileNo.toString()}</Td>
                  <Td>
                    {usersPermission.isEdit && <Button
                      colorScheme='blue'
                      onClick={() => handleEditEvent(item)}
                      size={'sm'}
                    >
                      {'Update'}
                    </Button>}{' '}
                    {usersPermission.isDelete ? (
                      <Button
                        colorScheme='red'
                        size={'sm'}
                        onClick={() => handleDeleteEvent(item)}
                      >
                        Delete
                      </Button>
                    ) : null}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td>No Record Found</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    )
  }
  const noViewPermission = () => {
    return (<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="Users" />
      <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop: '10px' }}>
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head">User</div>
            <h3 className="project-title fw-bold">User Listing</h3>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <Flex alignItems={'flex-end'} justifyContent={'end'} gap={'3'}>
            <Select w={'25%'} placeholder="Select Designation" onChange={(e) => setSelectedDesignation(e.target.value)}>
              {uniqueDesignations.map((designation, index) => (
                <option key={index} value={designation}>{designation}</option>
              ))}
            </Select>

            <Select w={'max-content'} placeholder="Select Employment Type" onChange={(e) => setSelectedEmploymentType(e.target.value)}>
              {uniqueEmploymentTypes.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </Select>

            <Box>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <SearchIcon color='gray.300' />
                </InputLeftElement>
                <Input size={'md'} placeholder='Enter user name' colorScheme='blue' variant='filled' onChange={(e) => setSearchValue(e.target.value)} />
              </InputGroup>
            </Box>
          </Flex>
          <div className="row d-flex justify-content-center h-100 pt-5">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
            <div className="card text-black" style={{ borderRadius: "10px" }}>
              <div className="d-flex justify-content-end mx-2 mb-2 mb-lg-2">
                {isCreate ? <Link className="btn btn-primary mt-3" to={"/Admin/AddUser"}>Add User</Link> : null}
              </div>
              <div className="card-body">
                {isView ? bindData() : noViewPermission()}
              </div>
            </div>
            {/* </div> */}
          </div>
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
            <Button mt='15px' colorScheme='blue' style={{ display: 'flex', gap: '5px' }} onClick={handlePrint}>
              Print
              <AiFillPrinter />
            </Button>
            <Button
              mt='15px'
              colorScheme='blue'
              style={{ display: 'flex', gap: '5px' }}
              onClick={exportToExcel}
            >
              Export to Excel
              <AiFillFileExcel />
            </Button>
          </div>
        </div>

      </section>
    </div>
  );
};

export default User;
